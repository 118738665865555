/* global google */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import Select from 'react-select'
import { api } from '../utils/Api'
import GoogleMapReact from 'google-map-react'

const HeatmapSellers = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const [dateChange, setDateChange] = useState(true)
    const [sellers, setSellers] = useState([])
    const [sellerPositions, setsellerPositions] = useState([])
    const [latitude, setLatitude] = useState(26.2787785)
    const [longitude, setLongitude] = useState(-98.4021818)
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [selectedSeller, setSelectedSeller] = useState({})
    const [noData, setNoData] = useState(false)

    const googleApi = process.env.GOOGLE_MAP_API || "AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU"

    useEffect(() => {
        const fechaIni = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        let fechaFin = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        if (endDate < startDate) {
            setEndDate(startDate)
            fechaFin = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        }

        if (dateChange) {
            if (selectedSeller) {
                setLoading(true)
                getJobs(fechaIni, fechaFin, selectedSeller.value)
            } else {
                setLoading(true)
                getJobs(fechaIni, fechaFin)
            }
        }

    }, [startDate, endDate, update])

    useEffect(() => {
        setLoading(true)
        getSellers()
    }, [])


    const heatmapOpt = {
        radius: 20,
        opacity: 0.6,
    }

    const getJobs = async (startDate, endDate, seller) => {
        setUpdate(false)
        let url = ""
        if (seller) {
            url = api + `/sellerjobs?startDate=${startDate}&endDate=${endDate}&seller=${seller}`
        } else {
            url = api + `/sellerjobs?startDate=${startDate}&endDate=${endDate}`
        }
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            if (data.length !== 0) {
                setNoData(false)
                const arrPos = []
                console.log(data)
                data.map((seller) => {
                    if (seller.latitude || seller.longitude) {
                        // console.log(seller.latitude);
                        // console.log(seller.longitude);
                        let latLngObj = {
                            lat: seller.latitude,
                            lng: seller.longitude
                        }
                        arrPos.push(latLngObj)
                    }
                })
                if (arrPos.length === 0) {
                    setNoData(true)
                    setLoading(false)
                } else {
                    setsellerPositions(arrPos)
                    setLatitude(arrPos[1].lat)
                    setLongitude(arrPos[1].lng)
                    setLoading(false)
                }
            } else {
                setNoData(true)
                setLoading(false)
            }

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getSellers = async () => {
        const url = api + '/userlist/Seller'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setSellers(data)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const changePositions = (seller) => {
        setSelectedSeller(seller)
        setDateChange(true)
        setUpdate(true)

    }

    const options = sellers.map((seller) => {
        return {
            value: seller._id,
            label: seller.name.toUpperCase()
        }
    })

    const defaultMap = {
        center: {
            lat: latitude,
            lng: longitude
        },
        zoom: 9
    }


    const TheMap = () => {
        if (noData) {
            return (
                <div className="d-flex justify-content-center">
                    <p>NO DATA FOR THAT DATE RANGE</p>
                </div>
            )
        } else {
            return (
                <div className="d-flex justify-content-center">
                    <div style={{ height: '100vh', width: '50%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: googleApi }}
                            defaultCenter={defaultMap.center}
                            defaultZoom={defaultMap.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            heatmapLibrary={true}
                            heatmap={{ positions: sellerPositions, options: heatmapOpt }}
                        >
                        </GoogleMapReact>
                    </div>
                </div>
            )
        }
    }

    return (
        <Layout
            title='Report'
            description='Heatmap Sellers Report'
        >
            <div>
                <div className="form-group row">
                    <div className='col-sm-4'>
                        <label className='text-muted'>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>End Date</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                                setDateChange(true)
                            }}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <label className='text-muted'>Seller</label>
                        <Select
                            name="form-field-name"
                            placeholder='Seller...'
                            onChange={(seller) => changePositions(seller)}
                            options={options}
                            isClearable={true}
                        />
                    </div>
                </div>
            </div>
            <div>
                <p>

                </p>
            </div>
            {
                loading ? <Spinner /> : <TheMap />

            }
        </Layout>
    )
}

export default HeatmapSellers