import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom';
const ETicketModifiedReport = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [loading, setLoading] = useState(true)
    const [term, setTerm] = useState('')
    const [loadList, setLoadList] = useState([])
    let history = useHistory()

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        getLoadList()
    }

    const getLoadList = async() => {
        setLoading(true)
        const start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        const url = api + `/etickets/search?startDate=${start}&endDate=${end}&term=${term}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setLoadList(data)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const RenderTable = () => {
        return <div style={{maxWidth: '100%', marginTop: 20}}>
            <table className="table">
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Driver</th>
                        <th>Truck</th>
                        <th>Delivery Ticket</th>
                        <th>Order Code</th>
                        <th>Job ID</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loadList.map((load) => {
                            return (
                                <tr key={load._id}>
                                    <td>{`${load.job.customer.customerNumber} ${load.job.customer.name}`}</td>
                                    <td>{load.date ? load.date.substring(0,10) : 'No Date'}</td>
                                    <td>{load.driver}</td>
                                    <td>{load.truckNumber}</td>
                                    <td><p onClick={() => history.push(`/e-ticket-modified/${load._id}`)}>
                                        <Link to={`/e-ticket-modified/${load._id}`}>
                                        {load.deliveryTicket}
                                        </Link>
                                    </p></td>
                                    <td>{load.orderCode}</td>
                                    <td>
                                        <Link to={'/eticketsjob/' + load.job._id}>
                                            {load.job.number}
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    }    

    const SearchCustomer = () => (
        <div className="form-group row">
            <div className="col-sm-3">
                <label className='text-muted'>&nbsp;</label>
                <input
                    type='text'
                    name='term'
                    size='40'
                    className="form-control"
                    placeholder="Search..."
                    defaultValue={term}
                    onBlur={(e) => setTerm(e.target.value)}
                />
            </div>
            <div className='col-sm-2'>
                <label className='text-muted'>Start Date</label>
                <DatePicker
                    selected={startDate}
                    minDate={new Date('01/01/2022')}
                    maxDate={new Date()}
                    onChange={(date) => setStartDate(date)}
                />
            </div>
            <div className='col-sm-2'>
                <label className='text-muted'>End Date</label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                    maxDate={new Date()}
                />
            </div>
            <div className="col-sm-2">
                <label className='text-muted'>&nbsp;</label>
                <button onClick={search} className='btn btn-primary w-100'>
                    <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                </button>
            </div>
        </div>
    )

    return (
        <Layout
            title='E-Tickets'
            description='Report'
            className='container col-md-10'
        >
            <SearchCustomer/>
            {
                loading ? <RenderLoading/> : <RenderTable/>
            } 

        </Layout>
    )
}

export default ETicketModifiedReport