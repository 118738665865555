import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api';
import axios from 'axios';
import { CustomSpinner } from '../utils/CustomComponents';

const Product = (props) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingForm, setLoadingForm] = useState(true)
    const [apiNotification, contextHolder] = notification.useNotification()

    useEffect(() => {
        getProduct()
    }, [])

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const getProduct = async () => {
        try {
            let url = api + `/psi/${props.match.params.id}` 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const { data } = await axios.get(url)

            setInitialValues({
                ...initialValues,
                psi: data.psi,
                description: data.description,
                price: data.price,
            })
            setLoading(false)
            setLoadingForm(false)

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to get product')
            console.error(err.message)
            return null
        }
    }

    const updateProduct = async (data) => {
        setLoading(true)

        const dataForm = {
            ...data,
            updatedBy: localStorage.getItem('userId')
        }

        try {
            let url = api + `/psi/${props.match.params.id}` 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, dataForm)
            openNotification('success', 'Success', 'Product updated')
            setTimeout(() => {
                setLoading(false)
                props.history.push('/psilist')
            }, 1000);
        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to update user')
            console.error(err.message)
            return null
        }
    }

    const deleteProduct = async () => {
        setLoading(true)

        const url = api + '/psi/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Product Deleted')
            setLoading(false)
            props.history.push('/psilist')
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const onFinish = (values) => {
        updateProduct(values)
    }

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            initialValues={initialValues}
            onFinish={onFinish}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Product'
                    name='psi'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill psi'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Description'
                    name='description'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill description'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Price'
                    name='price'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill price'
                        }
                    ]}
                    >
                        <InputNumber 
                        size='large' style={{width: '100%'}} 
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Col>
                { 
                    (
                        localStorage.getItem('email') === 'ec@57concrete.com' || 
                        localStorage.getItem('email') === 'eliud@gmail.com' ||
                        localStorage.getItem('email') === 'jp@57concrete.com' ||
                        localStorage.getItem('email') === 'vm@57concrete.com' ||
                        localStorage.getItem('email') === 'fc@57concrete.com'
                    )
                    &&
                    <Col>
                        <Popconfirm
                            title="Delete the product"
                            description="Are you sure to delete this product?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={deleteProduct}
                        >
                            <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                        </Popconfirm>
                    </Col> 
                }
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Product'
        description='Edit Product'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner /> : <CustomForm />}
        </Layout>
    )
}

export default Product