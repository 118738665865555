import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { connect } from 'react-redux'
import { getFutureJobs } from '../../actions/job'
import axios from 'axios'
import { api } from '../utils/Api'
import { Button, Card, Col, Divider, Flex, Row, Skeleton, Statistic, Typography } from 'antd'
import CountUp from 'react-countup'
const {Title, Text} = Typography

const formatter = (value) => <CountUp end={value} duration={2} separator="," />

const DashboardManagement = ({ getFutureJobs, history, job: { jobs, loading } }) => {
    const [todayJobs, setTodayJobs] = useState([])
    const [customLoading, setCustomLoading] = useState(true)

    useEffect(() => {
        getFutureJobs()
        getJobsToday()
    }, [])

    const getJobsToday = async () => {
        const today = new Date()
        const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : `${today.getMonth() + 1}`
        const day = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`
        const dateJob = `${today.getFullYear()}-${month}-${day}`
        const url = api + `/jobdate?date=${dateJob}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setTodayJobs(data)
            setCustomLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const showJobInspection = () => {
        history.push('/jobinspection')
    }

    const showJobPending = () => {
        history.push('/jobspending')
    }

    const showJobAppointment = () => {
        history.push('/jobappointment')
    }

    const showProduction = () => {
        history.push('/production')
    }

    const showReadOnly = (option) => {
        history.push(`/dailyreadonly/${option}`)
    }

    const onClickDailyAppointment = () => {
        history.push(`/dailyappointment`)
    }
    
    const onClickDailyAppointment2 = () => {
        history.push(`/dailyappointment2`)
    }

    const onClickNewJob2 = () => {
        history.push(`/newjob`)
    }

    const showOnTime = (quantity) => {
        if (quantity === "all") {
            return showTotal('ontime-true', 'jobs') + showTotal('ontime-false', 'jobs')
        } else if (quantity === "ontime") {
            let perc = (showTotal('ontime-true', 'jobs') / (showTotal('ontime-true', 'jobs') + showTotal('ontime-false', 'jobs'))) * 100
            return Math.round(perc)
        } else if (quantity === "notontime") {
            let perc = (showTotal('ontime-false', 'jobs') / (showTotal('ontime-true', 'jobs') + showTotal('ontime-false', 'jobs'))) * 100
            return Math.round(perc)
        }
    }

    const showTotal = (option, ydOrJobs) => {
        if (jobs && todayJobs.length > 0) {
            let jobsFiltered = (option === 'movedorders' || option === 'inspection' || option === 'pendingPayment' || option === 'appointment'
                ? jobs.filter(job => job.customer.creditHold !== true)
                : todayJobs.filter(job => job.customer.creditHold !== true))

            if (option === 'pending') { // PENDING
                //jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Trouble' || job.jobStatus === 'Active' || job.jobStatus === 'Date moved' || job.jobStatus === 'Ready to Load')
                jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Confirm')
            } else if (option === 'hotshot') { // HOTSHOT
                jobsFiltered = jobsFiltered.filter(job => job.hotShot === true)
            } else if (option === 'ontime-true') { // ONTIME-TRUE
                jobsFiltered = jobsFiltered.filter(job => job.onTime === true)
            } else if (option === 'ontime-false') { // ONTIME-FALSE
                jobsFiltered = jobsFiltered.filter(job => job.onTime === false)
            } else if (option === 'movedorders') { // MOVED ORDER
                jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Date moved')
            } else if (option === 'done') { // DONE
                jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'ok')
            } else if (option === 'inprogress' || option === 'inprogressTarget') { // IN PROGRESS
                jobsFiltered = jobsFiltered.filter(job => job.jobStatus === 'Active')
            } else if (option === 'inspection') { // INSPECTION
                jobsFiltered = jobsFiltered.filter(job => job.inspectionPassed !== true)
            } else if (option === 'pendingPayment') { // PENDING PAYMENT
                jobsFiltered = jobsFiltered.filter(job => job.paid === false)
                jobsFiltered = jobsFiltered.filter(job => job.customer.paymentType !== 'Credit')
            } else if (option === 'appointment') { // READY TO APPOINTMENT
                jobsFiltered = jobsFiltered.filter(job => job.hasAppointment === false)
            } else if (option === "production") { // PRODUCTION
                // Cuales jobs ?? 
            }

            let quantity = jobsFiltered.length
            let yd3 = 0

            // Sum of target YD3
            if (option === 'pendingPayment' || option === 'appointment' || option === 'inspection' || option === 'movedorders' || option === 'inprogressTarget' || option === 'pending') {
                jobsFiltered.forEach(job => { yd3 += (typeof job.targetYD3 === "string" ? parseFloat(job.targetYD3) : job.targetYD3) })
            } else { // Sum of load YD3
                jobsFiltered.forEach(job => { yd3 += parseInt(job.loadYD3) })
            }

            // console.log(`yards: ${yd3}`)
            // console.log(`quantity ${quantity}`)   

            // sumar pending
            // const pendientes = jobsFiltered.filter(job => job.hasAppointment === false).reduce((acc, jb) => acc + parseInt(jb.loadYD3), 0)

            // yd3 = yd3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Add commas 

            return (ydOrJobs === "yd" ? yd3 : quantity)
        }
    }

    const CustomDashboard = () => {
        return <div>
        <Row gutter={16} >
            <Col span={8}>
                <Card bordered style={{backgroundColor: '#14BF1D', cursor: 'pointer'}} onClick={(e) => showReadOnly("done")}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Done</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('done', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('done', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={8}>
                <Card bordered style={{backgroundColor: '#FFE000', cursor: 'pointer'}} onClick={(e) => showReadOnly("inprogress")}>
                {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>In Progress</Title>
                            <Row gutter={16} justify={'center'} align={'middle'} style={{width: '100%'}}> 
                                <Col span={6}>
                                    <Text>
                                        <Statistic title="Target YD3" value={showTotal('inprogressTarget', 'yd')} formatter={formatter} valueStyle={{color:'white'}} />
                                    </Text>
                                </Col>
                                <Col span={6}>
                                    <Text>
                                        <Statistic title="Load YD3" value={showTotal('inprogress', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                                    </Text>
                                </Col>
                                
                            </Row>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('inprogress', 'jobs')} formatter={formatter} valueStyle={{color:'white'}} />
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={8}>
                <Card bordered style={{backgroundColor: '#DF2D2D', cursor: 'pointer'}} onClick={(e) => showReadOnly("pending")}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Pending</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('pending', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('pending', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
        </Row>
        <Divider />
        <Button type='primary' style={{width:'100%'}} size='large' loading={customLoading} onClick={onClickDailyAppointment}>Daily Appointment</Button>
        <Divider />
        <Button type='primary' style={{width:'50%'}} size='large' loading={customLoading} onClick={onClickDailyAppointment2}>Daily Appointment v2.0</Button>
        <Button type='primary' style={{width:'50%'}} size='large' loading={customLoading} onClick={onClickNewJob2}>New Job v2.0</Button>
        <Divider />
        <Row gutter={16}>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#FF8C00', cursor: 'pointer' }} onClick={(e) => showReadOnly("movedorders")}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Moved Orders</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('movedorders', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('movedorders', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#3AC5FD', cursor: 'pointer' }} onClick={(e) => showReadOnly("hotshot")}>
                {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Hot Shot</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('hotshot', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('hotshot', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#b27e6b', cursor: 'pointer' }} onClick={(e) => showReadOnly("ontime-true")}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>On Time</Title>
                            <Text>
                                <Statistic title='%' value={showOnTime("ontime")} formatter={formatter} suffix="%" valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showOnTime("all")} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#95b7bd', cursor: 'pointer' }} onClick={(e) => showReadOnly("ontime-false")}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Not On Time</Title>
                            <Text>
                                <Statistic title='%' value={showOnTime("notontime")} formatter={formatter} suffix="%" valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showOnTime("all")} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#B1B1B1', cursor: 'pointer' }} onClick={showJobInspection}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Inspection</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('inspection', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('inspection', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#B1B1B1', cursor: 'pointer' }} onClick={showJobPending}>
                {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Pending Payment</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('pendingPayment', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('pendingPayment', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#B1B1B1', cursor: 'pointer' }} onClick={showJobAppointment}>
                    {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Ready to Appointment</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('appointment', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('appointment', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered style={{ backgroundColor: '#B1B1B1', cursor: 'pointer' }} onClick={showProduction}>
                {
                        customLoading ? 
                        <Skeleton active/>
                        :
                        <Flex align='center' justify='center' vertical>
                            <Title level={2} style={{color:'white'}}>Production</Title>
                            <Text>
                                <Statistic title="YD3" value={showTotal('production', 'yd')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                            <Divider />
                            <Text>
                                <Statistic title="Jobs" value={showTotal('production', 'jobs')} formatter={formatter} valueStyle={{color:'white'}}/>
                            </Text>
                        </Flex>
                    }
                </Card>
            </Col>
        </Row>
    </div>
    }

    return (
        <Layout
            title='Dashboard Management'
            description=''
        >
            <CustomDashboard />
        </Layout>
    )
}

const mapStateToProps = state => ({
    job: state.job
})

export default connect(mapStateToProps, { getFutureJobs })(DashboardManagement)