import { Button, Checkbox, Col, Form, Input, Popconfirm, Row, notification, Typography, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api';
import axios from 'axios';
import { CustomSpinner } from '../utils/CustomComponents';
import { rolePouredOptions } from '../../constants/Poured';
const {Text} = Typography

const Finance = (props) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(true)
    const [email, setEmail] = useState('')
    const [apiNotification, contextHolder] = notification.useNotification()

    useEffect(() => {
        getFinance()
    }, [])

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const duplicatedUserEmail = async (email) => {
        const url = api + '/useremail/' + email
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getFinance = async () => {
        try {
            let url = api + `/users/${props.match.params.id}` 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            const { data } = await axios.get(url)

            setInitialValues({
                ...initialValues,
                name: data.name,
                email: data.email,
                jobHold: data.jobHold ? data.jobHold : false,
                isPouredUser: data.isPouredUser ? data.isPouredUser : false,
                isActivePouredUser: data.isActivePouredUser ? data.isActivePouredUser : false,
                pouredRole: data.pouredRole || ''
            })
            setEmail(data.email)
            setLoading(false)
            setLoadingForm(false)

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to get user')
            console.error(err.message)
            return null
        }
    }

    const updateFinance = async (data) => {
        const user = {
            name: data.name,
            email: data.email,
            jobHold: data.jobHold ? data.jobHold : false,
            isPouredUser: data.isPouredUser,
            isActivePouredUser: data.isActivePouredUser,
        }

        if(data.pouredRole !== ''){
            user.pouredRole= data.pouredRole
        }

        if(data.password !== undefined && data.password !== null && data.password !== ''){
            user.password = data.password
        }

        try {
            let url = api + `/users/${props.match.params.id}` 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, user)
            openNotification('success', 'Success', 'Finance created')
            setLoading(false)
            props.history.push('/financelist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to update user')
            console.error(err.message)
            return null
        }
    }

    const deleteFinance = async () => {
        setLoading(true)

        const url = api + '/users/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Finance Deleted')
            setLoading(false)
            props.history.push('/financelist')
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }
    
    const validateUser = async (values) => {
        if(values.email === email){
            updateFinance(values)
        }else{
            const duplicadoEmail = await duplicatedUserEmail(values.email)
            
            if (duplicadoEmail) {
                openNotification('warning', 'Duplicated user', 'User alredy exist with Email')
                setLoading(false)
            }else {
                updateFinance(values)
            }
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        validateUser(values)
    }

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            initialValues={initialValues}
            onFinish={onFinish}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please fill email'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            {
                (localStorage.getItem('email') === 'eliud@gmail.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com') &&
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="password"
                            label="Change Password"
                            rules={[
                            {
                                min: 5,
                                message: 'Password must have at least 5 characters'
                            }
                            ]}
                            hasFeedback
                        >
                            <Input.Password size='large' />
                        </Form.Item>
                    </Col>
                </Row>
            }
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="jobHold"
                        valuePropName="checked"
                    >
                        <Checkbox>
                            Allow Job Hold
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            {
                (
                    localStorage.getItem('email') === 'ec@57concrete.com' || 
                    localStorage.getItem('email') === 'eliud@gmail.com' ||
                    localStorage.getItem('email') === 'jp@57concrete.com' ||
                    localStorage.getItem('email') === 'bp@57concrete.com' ||
                    localStorage.getItem('email') === 'vm@57concrete.com' ||
                    localStorage.getItem('email') === 'mgr@57concrete.com' ||
                    localStorage.getItem('email') === 'ji@57concrete.com'
                ) &&
                <Row gutter={16}>
                    <Col span={24}>
                        <Text strong>Poured Details</Text>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="isPouredUser"
                            valuePropName="checked"
                        >
                            <Checkbox>
                                Is Poured User? (App)
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="isActivePouredUser"
                            valuePropName="checked"
                        >
                            <Checkbox>
                                Is Active Poured User? (Poured Web)
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="pouredRole"
                            label='Poured Role'
                        >
                            <Select
                                options={rolePouredOptions}
                                style={{width: '100%'}}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            }
            <Row gutter={16}>
                <Col>
                    <Button type="primary" htmlType="submit" loading={loading} size='large'>
                        Save
                    </Button>
                </Col>
                {/* <Col>
                    <Popconfirm
                        title="Delete the user"
                        description="Are you sure to delete this user?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={deleteFinance}
                    >
                        <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                    </Popconfirm>
                </Col> */}
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Finance'
        description='Edit Finance'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner /> : <CustomForm />}
        </Layout>
    )
}

export default Finance