import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api } from '../utils/Api'
import { Typography, Flex, Button } from 'antd'
import Layout from '../layout/Layout'
import { CustomSpinner, CustomTable } from '../utils/CustomComponents'
import {
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons'
const { Text } = Typography

const PsiList = ({history}) => { 
    
    const [loading, setLoading] = useState(true)
    const [productList, setProductList] = useState([])

    /**
     ** Options header (columns)
    */
    const columns = [
        {
            title: '',
            dataIndex: 'edit',
            width: 100,
            render: (_, record) => {
              return (localStorage.getItem('email') === 'ec@57concrete.com' || 
              localStorage.getItem('email') === 'eliud@gmail.com' || 
              localStorage.getItem('email') === 'jp@57concrete.com' ||
              localStorage.getItem('email') === 'ji@57concrete.com' ||
              localStorage.getItem('email') === 'vm@57concrete.com' ||
              localStorage.getItem('email') === 'fc@57concrete.com' ||
              localStorage.getItem('email') === 'rl@57concrete.com'
              ) ? <Button 
              type="primary" 
              icon={<EditFilled twoToneColor={'white'}/>} 
              onClick={e=> history.push(`/psi/${record.id}`)}
              size='large'
              />
              :
              ''
            }
        },
        {
            title: 'Product/Service',
            dataIndex: 'name',
            key: 'name',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (value) => {
                return <Text>{value}</Text>
            }
        },
        
    ]

    useEffect(() => {
        getProductList()
    }, [])
    
    const getProductList = async() => {
        setLoading(true)

        const url = api + '/psi'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            let list = []
            data.map((item, index) => {
                list.push({
                    key: index,
                    id: item._id,
                    name: item.psi,
                    description: item.description,
                    price: item.price ? `$ ${item.price.toFixed(3)}` : '$ 0',
                })
            })
            setProductList(list)
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const addNewProduct = () => {
        history.push('/psinew')
    }
    
    const RenderTable = () => {
        return <Flex gap={'middle'} vertical>
            {
                (localStorage.getItem('email') === 'ec@57concrete.com' || 
                localStorage.getItem('email') === 'eliud@gmail.com' || 
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ji@57concrete.com' ||
                localStorage.getItem('email') === 'vm@57concrete.com' ||
                localStorage.getItem('email') === 'rl@57concrete.com'
                )
                &&
                <Button
                    onClick={addNewProduct}
                    type="primary"
                    style={{
                        width: 150,
                    }}
                    icon={<PlusCircleFilled />}
                    size='large'
                >
                    New Product
                </Button>
            }
            <CustomTable 
            columns={columns}
            data={productList}
            />
        </Flex>
    }

    return (
        <Layout
            title='Products'
            description='Product List'
        >
            <Flex vertical gap={'large'}>
                {
                    loading ? <CustomSpinner/> : <RenderTable/>
                } 
            </Flex>
        </Layout>
    )
}

export default PsiList