/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { logout } from '../../actions/auth'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Menu } from 'antd'
import {
  BarChartOutlined,
  DatabaseOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  HomeOutlined,
  TruckOutlined,
  FileOutlined,
  UploadOutlined,
  WechatWorkOutlined,
  SettingOutlined,
  ProfileOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  ProductOutlined,
} from '@ant-design/icons'

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label
    }
}

const CustomMenu = ({ history, auth: { isAuthenticated, loading }, logout }) => {

    const items = [
        (   localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller' ||
            localStorage.getItem('role') === 'Finance' 
        ) && 
        getItem('Home', 'dashboardmanagement', <HomeOutlined />),
    
        (   localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller' ||
            localStorage.getItem('role') === 'Finance' 
        ) && 
        getItem('Sales', 'sales', <BarChartOutlined />,[
            (
                localStorage.getItem('role') === 'Admin' ||
                localStorage.getItem('role') === 'Seller' ||
                localStorage.getItem('role') === 'Finance'
            ) 
            && getItem('Customers', 'clientlist', <UserOutlined />),
            (
                localStorage.getItem('role') === 'Admin' ||
                localStorage.getItem('role') === 'Finance' ||
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'rl@57concrete.com' 
            ) && getItem('SMS', 'smslist', <WechatWorkOutlined />),
            // (
            //     localStorage.getItem('role') === 'Admin' ||
            //     localStorage.getItem('role') === 'Finance'
            // ) && getItem('SMS List', 'smslist', <WechatWorkOutlined />),
            getItem('Sales Dashboard', 'salesdashboard'),
            getItem('Opportunity Status', 'opportunitystatus'),
            getItem('Quadrants', 'quadrants'),
            getItem('Top Customers', 'topcustomers'),
            getItem('Top Localities', 'toplocalities'),
            getItem('Top Foreman', 'topforeman'),
            getItem('Report Customer Sales', 'reportcustomersales'),
            getItem('List Price', 'listprice'),
        ]),
    
        (   localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller' ||
            localStorage.getItem('role') === 'Finance' 
        ) && 
        getItem('Job', 'job', <BarChartOutlined />,[
            getItem('New Job', 'jobnuevo'),
            getItem('New In Call', 'quotenew'),
            getItem('New Quote', 'newquote'),
            getItem('Quote List', 'quotelisting'),
        ]),
        
        (   localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller' ||
            localStorage.getItem('role') === 'Finance' 
        ) && 
        getItem('Daily Appointment', 'dailyappointment', <DatabaseOutlined />),
    
        (
            (localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Finance' ||
            localStorage.getItem('email') === 'dm@57concrete.com') &&
            localStorage.getItem('email') !== 'jip@57concrete.com'
        ) && getItem('Trucks Map', 'trucksmap', <TruckOutlined />),
    
        (   (localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller' ||
            localStorage.getItem('role') === 'Finance') &&
            localStorage.getItem('email') !== 'jip@57concrete.com'
        ) && 
        getItem('Lineup', 'lineup', <FileOutlined />),
    
        (
            (localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Finance') &&
            localStorage.getItem('email') !== 'jip@57concrete.com'
        ) && getItem('Finance', 'finance', <PieChartOutlined />,[
            getItem('Credits', 'creditlist'),
            getItem('Referrals', 'referrallist'),
            getItem('Daily Sales', 'dailysales'),
            getItem('Period Sales', 'periodsales'),
            getItem('Customer with invoices', 'clientwithinvoice'),
            getItem('Payment Report', 'paymentreport'),
            getItem('Mix Design Invoices', 'mixdesignreport'),
            getItem('Sales by seller', 'salesbyseller'),
        ]),
    
        (   localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller' ||
            localStorage.getItem('role') === 'Finance' 
        ) && 
        getItem('Uploads', 'uploadlist', <UploadOutlined />),
    
        (   localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller' ||
            localStorage.getItem('role') === 'Finance' ||
            localStorage.getItem('email') === 'ah@57concrete.com'
        ) && 
        getItem('Reports', 'reports', <ProfileOutlined />, [
            getItem('Default Job Report', 'reportjob'),
            getItem('Default Load Report', 'reportload'),
            getItem('Custom Job Report', 'newreport'),
            getItem('Report Catalog', 'reportlist'),
            getItem('Capacity Report', 'capacity'),
            getItem('Availability Report', 'availability'),
            getItem('Truck Efficiency', 'truckefficiency'),
            getItem('Clock Report', 'clockreport'),
            getItem('Driver Trips', 'reporttrips'),
            getItem('Driver Payments', 'reportpaymentdriver'),
            getItem('Truck Trips', 'reporttrucktrips'),
            getItem('Truck Yards', 'reporttruckyards'),
            (
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com' ||
                localStorage.getItem('email') === 'ac@57concrete.com' ||
                localStorage.getItem('email') === 'fc@57concrete.com' ||
                localStorage.getItem('email') === 'hp@57concrete.com' ||
                localStorage.getItem('email') === 'bp@57concrete.com' ||
                localStorage.getItem('email') === 'al@57concrete.com' ||
                localStorage.getItem('email') === 'mgr@57concrete.com' ||
                localStorage.getItem('email') === 'ah@57concrete.com' ||
                localStorage.getItem('email') === 'cdl@57concrete.com' ||
                localStorage.getItem('email') === 'eliud@gmail.com'
            )
            && getItem('E-Tickets Report', 'reportetickets'),
            (
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com' ||
                localStorage.getItem('email') === 'ac@57concrete.com' ||
                localStorage.getItem('email') === 'fc@57concrete.com' ||
                localStorage.getItem('email') === 'hp@57concrete.com' ||
                localStorage.getItem('email') === 'bp@57concrete.com' ||
                localStorage.getItem('email') === 'mgr@57concrete.com' ||
                localStorage.getItem('email') === 'eliud@gmail.com')
            && getItem('Inspections Report', 'reportinspections'),
            (
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ji@57concrete.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com' ||
                localStorage.getItem('email') === 'ac@57concrete.com' ||
                localStorage.getItem('email') === 'fc@57concrete.com' ||
                localStorage.getItem('email') === 'hp@57concrete.com' ||
                localStorage.getItem('email') === 'bp@57concrete.com' ||
                localStorage.getItem('email') === 'mgr@57concrete.com' ||
                localStorage.getItem('email') === 'eliud@gmail.com')
            && getItem('Inspections Driver Report', 'reportinspectionsdriver'),
            (
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com' ||
                localStorage.getItem('email') === 'ac@57concrete.com' ||
                localStorage.getItem('email') === 'fc@57concrete.com' ||
                localStorage.getItem('email') === 'hp@57concrete.com' ||
                localStorage.getItem('email') === 'bp@57concrete.com' ||
                localStorage.getItem('email') === 'mgr@57concrete.com' ||
                localStorage.getItem('email') === 'eliud@gmail.com'
            )
            && getItem('Inspections Defects', 'inspectiondefects'),
            (
                localStorage.getItem('email') === 'eliud@gmail.com' ||
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com' ||
                localStorage.getItem('email') === 'bp@57concrete.com' ||
                localStorage.getItem('email') === 'mgr@57concrete.com' ||
                localStorage.getItem('email') === 'fc@57concrete.com'
            )
            && getItem('Failures Report', 'reportfailures'),
            getItem('Quality Control Jobs', 'reportqualitycontrol'),
            (
                localStorage.getItem('email') === 'eliud@gmail.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com'
            )
            && getItem('History of Loads and Jobs', 'historyreport'),
            (
                localStorage.getItem('email') === 'jp@57concrete.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com' ||
                localStorage.getItem('email') === 'fc@57concrete.com' ||
                localStorage.getItem('email') === 'eliud@gmail.com'
            )
            && getItem('Etickets Modified', 'eticketsmodifiedreport'),
        ]),
    
        (
            localStorage.getItem('email') === 'eliud@gmail.com' ||
            localStorage.getItem('email') === 'ec@57concrete.com' ||
            localStorage.getItem('email') === 'bp@57concrete.com' ||
            localStorage.getItem('email') === 'jp@57concrete.com' ||
            localStorage.getItem('email') === 'fc@57concrete.com' ||
            localStorage.getItem('email') === 'ji@57concrete.com' ||
            localStorage.getItem('email') === 'mgr@57concrete.com' ||
            localStorage.getItem('email') === 'eci@57concrete.com' ||
            localStorage.getItem('role') === 'Mechanic' ||
            localStorage.getItem('manager') === true ||
            localStorage.getItem('director') === true
        ) && 
        getItem('WO', 'wo', <ProfileOutlined />, [
            (
                localStorage.getItem('role') !== 'Mechanic'
            ) 
            && getItem('Work Orders', 'workorders'),
            (localStorage.getItem('role') === 'Mechanic') &&
            getItem('My Work Orders', `workorders/${localStorage.getItem('userId')}`),
        ]),

        (
            localStorage.getItem('email') === 'eliud@gmail.com' ||
            localStorage.getItem('email') === 'ec@57concrete.com' ||
            localStorage.getItem('email') === 'bp@57concrete.com' ||
            localStorage.getItem('email') === 'jp@57concrete.com' ||
            localStorage.getItem('email') === 'fc@57concrete.com' ||
            localStorage.getItem('email') === 'ji@57concrete.com' ||
            localStorage.getItem('email') === 'mgr@57concrete.com' ||
            localStorage.getItem('role') === 'AP' ||
            localStorage.getItem('manager') === true ||
            localStorage.getItem('director') === true 
        ) && 
        getItem('Inventory', 'inventory', <ProductOutlined />, [
            getItem('Inventory', 'inventorylist'),
            getItem('Provider', 'providerlist'),
            getItem('Request Inventory', 'inventoryrequestedlist'),
        ]),
    
        (   
            (localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Finance') &&
            localStorage.getItem('email') !== 'jip@57concrete.com'
        ) && 
        getItem('RH', 'rh', <TeamOutlined />, [
            getItem('Employees', 'employees'),
            getItem('Departments', 'departments'),
            getItem('Positions', 'positions'),
            (
                localStorage.getItem('email') === 'eliud@gmail.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com'
            ) 
            && getItem('Email Validation', 'emailsvalidation'),
        ]),
        (localStorage.getItem('email') === 'ec@57concrete.com' || 
        localStorage.getItem('email') === 'eliud@gmail.com' || 
        localStorage.getItem('email') === 'jp@57concrete.com' ||
        localStorage.getItem('email') === 'ji@57concrete.com' ||
        localStorage.getItem('email') === 'vm@57concrete.com' ||
        localStorage.getItem('email') === 'fc@57concrete.com' ||
        localStorage.getItem('email') === 'bp@57concrete.com' ||
        localStorage.getItem('email') === 'mgr@57concrete.com' ||
        localStorage.getItem('email') === 'cdl@57concrete.com' ||
        localStorage.getItem('email') === 'mb@57concrete.com' ||
        localStorage.getItem('email') === 'mv@57concrete.com' ||
        localStorage.getItem('email') === 'ah@57concrete.com' ||
        localStorage.getItem('email') === 'bs@57concrete.com' ||
        localStorage.getItem('email') === 'jip@57concrete.com'
        ) && 
        getItem('Web Logistics', 'weblogisitcs', <TeamOutlined />, [
            getItem('Logistics Dashboard', 'logisticsdashboard'),
            getItem('Logistics Drivers', 'logisticsdriverlist'),
            getItem('Logistics Trucks', 'logisticstrucklist'),
        ]),
    
        (
            localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Finance'
        )
        && getItem('Settings', 'settings', <SettingOutlined />),
    
        ( localStorage.getItem('role') === 'Admin' ||
            localStorage.getItem('role') === 'Seller'
        ) && 
        getItem('Forecast', `forecastbyseller/${localStorage.getItem('userId')}`, <UnorderedListOutlined />),
        
        getItem('Logout', '', <LogoutOutlined />),
    ]

    let location = useLocation()
    const [itemList, setItemList] = useState([])
    const [current, setCurrent] = useState(
        location.pathname === "/" || location.pathname === ""
            ? ""
            : location.pathname.replace('/','')
    )
    useEffect(() => {
        if (location) {
            setItemList(items)
            if( current !== location.pathname ) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);

    function handleClick(e) {
        setCurrent(e.key);
        if(e.key === ''){
            logout()
        }
        history.push(`/${e.key}`)
    }

    return (
        <>
            {
                localStorage.getItem('role') !== null &&
                <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[current]}
                items={itemList}
                style={{
                    flex: 1,
                    minWidth: 0,
                }}
                onClick={handleClick}
                />
            }
        </>
    )
}

Menu.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps,
    { logout }
)(withRouter(CustomMenu))
