import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import Geocode from "react-geocode"
import Marker from './Marker'
import axios from 'axios'
import { api } from '../utils/Api'
import { CustomSpinner } from '../utils/CustomComponents'

const AddresJob = props => {
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    // const [latitude, setLatitude] = useState(26.2787785)
    // const [longitude, setLongitude] = useState(-98.4021818)
    const [destLat, setDestLat] = useState('')
    const [destLng, setDestLng] = useState('')
    // const [distance, setDistance] = useState(null)
    // const [duration, setDuration] = useState(null)
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState('')

    const googleApi = process.env.GOOGLE_MAP_API || "AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU"

    useEffect(() => {
        if(props.plant){
            getPlantInfo()
        }
        // if (props.lat) {
        //     setDestLat(props.lat)
        //     setDestLng(props.lng)
        // } else {
        //     getCoordinates(props.address)
        // }
    }, [])


    const getPlantInfo = async () => {
        const url = api + `/plant/${props.plant}`        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setLatitude(data.latitude)
            setLongitude(data.longitude)   
            setName(`57Concrete ${data.name}`)       
            if (props.lat && props.lng) {
                setDestLat(props.lat)
                setDestLng(props.lng)
            } 
            // else {
            //     getCoordinates(props.address)
            // }  
            setLoading(false)
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const defaultMap = {
        center: {
            lat: latitude, 
            lng: longitude
        },
        zoom: 10
      }

    // address geocoding
    // const getCoordinates = address => {
    //     Geocode.setApiKey(googleApi)
    //     // Geocode.enableDebug()
    //     Geocode.fromAddress(address).then(
    //         (response) => {
    //           const { lat, lng } = response.results[0].geometry.location
    //           setDestLat(lat)
    //           setDestLng(lng)
    //           calculateDistance(lat, lng)
    //         },
    //         (error) => {
    //             console.error(error)
    //         }
    //       )        
    // }

    // Distance to customer
    // const calculateDistance = async (lat, lng) => {
    //     const ubicacion = {
    //         lat,
    //         lng
    //     }

    //     const url = api + '/distance'

    //     try {
    //         const { data } = await axios.post(url, ubicacion)
    //         const distance = data.distance.text
    //         const duration = data.duration.text
    //         const recorrido = distance.split(' ')

    //         if (parseFloat(recorrido[0].trim()) > 100) {
    //         } else {
    //             setDistance(distance)
    //             setDuration(duration)
    //         }
    //     } catch (err) {
    //         console.error(err)
    //         return null
    //     }
    // }

    // show text in map
    const AnyReactComponent = ({ text }) => <div style={{width: 300}}>{text}</div>

    return (
        <div>
            {/* {
                distance &&
                <div>
                    Distance: {distance}, Time to destination: {duration}
                </div>
            } */}

            <div style={{ height: '60vh', width: '100%' }}>     
            {
                loading ? 
                <CustomSpinner />
                :
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApi }}
                    defaultCenter={defaultMap.center}
                    defaultZoom={defaultMap.zoom}
                    yesIWantToUseGoogleMapApiInternals
                >                        
                    <Marker 
                        lat={latitude}
                        lng={longitude}
                        color="red"
                    />
                    <AnyReactComponent
                        lat={latitude}
                        lng={longitude}
                        text={name}
                    />
                    {
                        destLat &&
                        <Marker
                            lat={destLat}
                            lng={destLng}
                            color="green"
                        />
                    }
                    {
                        destLat &&
                        <AnyReactComponent
                            lat={destLat}
                            lng={destLng}
                            text={props.address}
                        />
                    }
                </GoogleMapReact>    
                
                }
            </div>
        </div>
    )

}

export default AddresJob