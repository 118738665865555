import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Layout from '../layout/Layout'
import DatePicker from 'react-datepicker'
import Spinner from '../layout/Spinner'
import { api } from '../utils/Api'
import { CSVLink } from "react-csv"
import QuadrantModal from './QuadrantModal'
import SellerModal from './SellerModal'
import { formatNumberThousands } from '../utils/Convert'

const QuadrantsReport = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [loading, setLoading] = useState(true)
    const [gcQuadrant, setGCQuadrant] = useState([])
    const [ccQuadrant, setCCQuadrant] = useState([])
    const [cemQuadrant, setCEMQuadrant] = useState([])
    const [govQuadrant, setGOVQuadrant] = useState([])
    const [gcQuadrantData, setGCQuadrantData] = useState([])
    const [ccQuadrantData, setCCQuadrantData] = useState([])
    const [cemQuadrantData, setCEMQuadrantData] = useState([])
    const [govQuadrantData, setGOVQuadrantData] = useState([])
    const [volumeGC, setVolumeGC] = useState(0)
    const [volumeCC, setVolumeCC] = useState(0)
    const [volumeCEM, setVolumeCEM] = useState(0)
    const [volumeGOV, setVolumeGOV] = useState(0)
    const [volumeGCQuantity, setVolumeGCQuantity] = useState(0)
    const [volumeCCQuantity, setVolumeCCQuantity] = useState(0)
    const [volumeCEMQuantity, setVolumeCEMQuantity] = useState(0)
    const [volumeGOVQuantity, setVolumeGOVQuantity] = useState(0)
    const [tableCsvContent, setTableCsvContent] = useState([])
    const [showQuadrantModal, setShowQuadrantModal] = useState(false)
    const [selectedQuadrantType, setSelectedQuadrantType] = useState('')
    const [selectedQuadrant, setSelectedQuadrant] = useState([])
    const [showSellerModal, setShowSellerModal] = useState(false)
    const [selectedSeller, setSelectedSeller] = useState('')

    useEffect(() => {
        getQuadrantsYards()
    }, [startDate, endDate])

    useEffect(() => {
        let tableContent = []
        ccQuadrant.map((row) => {
            tableContent.push({quadrantName: 'Construction Companies', quadrantType: 'CC', seller: row.seller, volume: row.volume, percentage: row.percentage})
        })
        gcQuadrant.map((row) => {
            tableContent.push({quadrantName: 'General Contractors', quadrantType: 'GC', seller: row.seller, volume: row.volume, percentage: row.percentage})
        })
        cemQuadrant.map((row) => {
            tableContent.push({quadrantName: 'Cement / Particular', quadrantType: 'CEM', seller: row.seller, volume: row.volume, percentage: row.percentage})
        })
        govQuadrant.map((row) => {
            tableContent.push({quadrantName: 'Government & DOT', quadrantType: 'GOV', seller: row.seller, volume: row.volume, percentage: row.percentage})
        })
        setTableCsvContent(tableContent)
    }, [!loading])

    const columns = [
        { key: 'quadrantName', name: 'Quadrant Name' },
        { key: 'quadrantType', name: 'Quadrant Type'},
        { key: 'seller', name: 'Seller' },
        { key: 'volume', name: 'Volume' },
        { key: 'percentage', name: 'Percentage (%)' },
    ]

    const headers = () => {
        const columnsCSV = columns
        return columnsCSV.map((item) => {
            return {
                key: item.key,
                label: item.name
            }
        })
    }

    const getQuadrantsYards = async () => {
        
        const start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        const end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

        const url = api + `/jobquadrants?startDate=${start}&endDate=${end}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setLoading(true)
            if(data.length !== 0){
                const sumVolumeTotal = data.reduce((acc, job) => acc + job.loadYD3, 0 )

                const groupByQuadrant = data.reduce((group, job) => {
                    if(job.customer !== null && job.customer.quadrantType !== null){
                        const { quadrantType } = job.customer;
                        group[quadrantType] = group[quadrantType] ?? [];
                        group[quadrantType].push(job);
                    }
                    return group;
                }, {});
                const groupedByQuadrantTypes = Object.values(groupByQuadrant)
                let semQuadrantNew = []
                let semQuadrantData = []
                groupedByQuadrantTypes.map((quadrant) => {
                    const quadrantType = quadrant[0].customer.quadrantType
                    
                    const groupByQuadrantSeller = quadrant.reduce((group, item) => {
                        if(item.seller !== null && item.seller.name !== null){
                            const { name } = item.seller;
                            group[name] = group[name] ?? [];
                            group[name].push(item);

                        }
                        return group;
                    }, {});
                    const groupByQuadrantSellerTypes = Object.values(groupByQuadrantSeller)
                    let quadrantTable = []
                    groupByQuadrantSellerTypes.forEach((item) => {
                        const sumLoad = item.reduce((acc, job) => acc + job.loadYD3, 0 )
                        if(item[0].seller !== null && item[0].seller.name !== null){
                            if(item[0].seller.name === 'Jesus Cantu'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Edgar Briones'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Ramiro Robledo'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Juan Hernandez'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Diana Reyes'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Fernando Ramirez'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Cristian Torres'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Eduardo Salgado'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'David Santamaria'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Erik Pecina'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else if(item[0].seller.name === 'Oscar Valadez'){
                                quadrantTable.push({seller: item[0].seller.name, volume: sumLoad})
                            }else{
                                if(quadrantTable.find(element => element.seller === 'CORPORATIVO') === undefined){
                                    quadrantTable.push({seller: 'CORPORATIVO', volume: sumLoad})
                                }else{
                                    const index = quadrantTable.findIndex(element => element.seller === 'CORPORATIVO')
                                    quadrantTable[index] = {seller: 'CORPORATIVO', volume: quadrantTable[index].volume + sumLoad}
                                }
                            }
                        }
                    })
                    switch (quadrantType) {
                        case 'CC':
                            const sumVolumeCC = quadrantTable.reduce((acc, item) => acc + item.volume, 0 )
                            setVolumeCC(((sumVolumeCC * 100) / sumVolumeTotal).toFixed(2))
                            setVolumeCCQuantity(sumVolumeCC.toFixed(2))
                            setCCQuadrantData(quadrant)
                            quadrantTable.map((item)=>{
                                item['percentage'] = ((item['volume']*100)/sumVolumeCC).toFixed(2) + '%'
                            })
                            setCCQuadrant(quadrantTable)
                            break;
                        case 'GC':
                            const sumVolumeGC = quadrantTable.reduce((acc, item) => acc + item.volume, 0 )
                            setVolumeGC(((sumVolumeGC * 100) / sumVolumeTotal).toFixed(2))
                            setVolumeGCQuantity(sumVolumeGC.toFixed(2))
                            setGCQuadrantData(quadrant)
                            quadrantTable.map((item)=>{
                                item['percentage'] = ((item['volume']*100)/sumVolumeGC).toFixed(2) + '%'
                            })
                            setGCQuadrant(quadrantTable)
                            break;
                        case 'CEM':
                            if(semQuadrantNew.length === 0){
                                semQuadrantNew = [...quadrantTable]
                                const sumVolumeCEM = semQuadrantNew.reduce((acc, item) => acc + item.volume, 0 )
                                setVolumeCEM(((sumVolumeCEM * 100) / sumVolumeTotal).toFixed(2))
                                setVolumeCEMQuantity(sumVolumeCEM.toFixed(2))
                                semQuadrantData = quadrant
                                setCEMQuadrantData(semQuadrantData)
                                quadrantTable.map((item)=>{
                                    item['percentage'] = ((item['volume']*100)/sumVolumeCEM).toFixed(2) + '%'
                                })
                                setCEMQuadrant(quadrantTable)
                            }else{
                                semQuadrantNew.forEach((item) => {
                                    quadrantTable.forEach((summary) => {
                                        if(item.seller === summary.seller){
                                            item.volume = (item.volume + summary.volume)
                                        }
                                    })
                                })

                                const names = semQuadrantNew.map((item) => {return item.seller})
                                quadrantTable.forEach((item) => {
                                    if(!names.includes(item.seller)){
                                        semQuadrantNew.push(item)
                                    } 
                                })
                                
                                quadrant.map((item => {
                                    semQuadrantData.push(item)
                                }))

                                const sumVolumeNewCEM = semQuadrantNew.reduce((acc, item) => acc + item.volume, 0 )
                                setVolumeCEM(((sumVolumeNewCEM * 100) / sumVolumeTotal).toFixed(2))
                                setVolumeCEMQuantity(sumVolumeNewCEM.toFixed(2))
                                setCEMQuadrantData(semQuadrantData)
                                semQuadrantNew.map((item)=>{
                                    item['percentage'] = ((item['volume']*100)/sumVolumeNewCEM).toFixed(2) + '%'
                                })
                                setCEMQuadrant(semQuadrantNew)
                            }
                            break;
                        case 'GOV':
                            const sumVolumeGOV = quadrantTable.reduce((acc, item) => acc + item.volume, 0 )
                            setVolumeGOV(((sumVolumeGOV * 100) / sumVolumeTotal).toFixed(2))
                            setVolumeGOVQuantity(sumVolumeGOV.toFixed(2))
                            setGOVQuadrantData(quadrant)
                            quadrantTable.map((item)=>{
                                item['percentage'] = ((item['volume']*100)/sumVolumeGOV).toFixed(2) + '%'
                            })
                            setGOVQuadrant(quadrantTable)
                            break;
                        case 'IC':
                            if(semQuadrantNew.length === 0){
                                semQuadrantNew = [...quadrantTable]
                                const sumVolumeCEM = semQuadrantNew.reduce((acc, item) => acc + item.volume, 0 )
                                setVolumeCEM(((sumVolumeCEM * 100) / sumVolumeTotal).toFixed(2))
                                setVolumeCEMQuantity(sumVolumeCEM.toFixed(2))

                                semQuadrantData = quadrant
                                setCEMQuadrantData(semQuadrantData)
                                quadrantTable.map((item)=>{
                                    item['percentage'] = ((item['volume']*100)/sumVolumeCEM).toFixed(2) + '%'
                                })
                                setCEMQuadrant(quadrantTable)
                            }else{
                                semQuadrantNew.forEach((item) => {
                                    quadrantTable.forEach((summary) => {
                                        if(item.seller === summary.seller){
                                            item.volume = (item.volume + summary.volume)
                                        }
                                    })
                                })

                                const names = semQuadrantNew.map((item) => {return item.seller})
                                quadrantTable.forEach((item) => {
                                    if(!names.includes(item.seller)){
                                        semQuadrantNew.push(item)
                                    } 
                                })

                                quadrant.map((item => {
                                    semQuadrantData.push(item)
                                }))

                                const sumVolumeNewCEM = semQuadrantNew.reduce((acc, item) => acc + item.volume, 0 )
                                setVolumeCEM(((sumVolumeNewCEM * 100) / sumVolumeTotal).toFixed(2))
                                setVolumeCEMQuantity(sumVolumeNewCEM.toFixed(2))
                                setCEMQuadrantData(semQuadrantData)
                                semQuadrantNew.map((item)=>{
                                    item['percentage'] = ((item['volume']*100)/sumVolumeNewCEM).toFixed(2) + '%'
                                })
                                setCEMQuadrant(semQuadrantNew)
                            }
                            break;
                        default:
                            break;
                    }
                })
            }
            setLoading(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const toggleModal = () => {
        setShowQuadrantModal(!showQuadrantModal)
    }

    const openQuadrantModal = (type) => {
        
        switch (type) {
            case 'cc':
                setSelectedQuadrantType('Construction Companies')
                setSelectedQuadrant(ccQuadrantData)
                break;
            case 'cem':
                setSelectedQuadrantType('Cement / Particular')
                setSelectedQuadrant(cemQuadrantData)
                break;
            case 'gc':
                setSelectedQuadrantType('General Contractors')
                setSelectedQuadrant(gcQuadrantData)
                break;
            case 'gov':
                setSelectedQuadrantType('Government & DOT')
                setSelectedQuadrant(govQuadrantData)
                break;
            default:
                break;
        }

        setShowQuadrantModal(!showQuadrantModal)
    }

    const toggleSellerModal = () => {
        setSelectedQuadrant([])
        setShowSellerModal(!showSellerModal)
    }

    const openSellerModal = (type, seller) => {
        
        switch (type) {
            case 'cc':
                setSelectedQuadrant(ccQuadrantData)
                break;
            case 'cem':
                setSelectedQuadrant(cemQuadrantData)
                break;
            case 'gc':
                setSelectedQuadrant(gcQuadrantData)
                break;
            case 'gov':
                setSelectedQuadrant(govQuadrantData)
                break;
            default:
                break;
        }

        setSelectedSeller(seller)
        setShowSellerModal(!showSellerModal)
    }

    const RenderLoading = () => {
        return <Spinner />
    }

    const RenderQuadrants = () => {
        return <div style={{maxWidth: '100%', marginTop: 20}}>
            <div className="container">
                <div className="row">
                    <div className="col-5">
                        <a href="#" className='h3' onClick={() => openQuadrantModal('cc')}>Construction Companies</a>
                        <div className='row'>
                            <div className='col-8'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Seller</th>
                                            <th scope="col">Volume</th>
                                            <th scope="col">Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ccQuadrant.map((item, key) => {
                                                return (
                                                <tr key={key}>
                                                    <td><a href="#" className='text-decoration-none' onClick={() => openSellerModal('cc',item.seller)}>{item.seller}</a></td>
                                                    <td>{formatNumberThousands(item.volume.toFixed(2))}</td>
                                                    <td>{item.percentage}</td>
                                                </tr> )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-4 text-center fw-bold'>
                                <p>Total</p>
                                <p>({volumeCC}%)</p>
                                <p>{formatNumberThousands(volumeCCQuantity)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 pt-auto">
                        <p className="bg-success mb-0 fw-bold text-white"  style={{textOrientation: 'upright', writingMode: 'vertical-lr', height:'45%'}}>+ VOLUME &nbsp;</p>
                    </div>
                    <div className="col-5">
                        <a href="#" className='h3' onClick={() => openQuadrantModal('gc')}><p className='text-end m-0'>General Contractors</p></a>
                        <div className='row'>
                            <div className='col-4 text-center fw-bold'>
                                <p>Total</p>
                                <p>({volumeGC}%)</p>
                                <p>{formatNumberThousands(volumeGCQuantity)}</p>
                            </div>
                            <div className='col-8'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Seller</th>
                                            <th scope="col">Volume</th>
                                            <th scope="col">Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            gcQuadrant.map((item, key) => {
                                                return (
                                                <tr key={key}>
                                                    <td><a href="#" className='text-decoration-none' onClick={() => openSellerModal('gc',item.seller)}>{item.seller}</a></td>
                                                    <td>{formatNumberThousands(item.volume.toFixed(2))}</td>
                                                    <td>{item.percentage}</td>
                                                </tr> )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 bg-success text-white">
                        <p className='m-0 fw-bold'>+ FREQUENCY</p>
                    </div>
                    <div className="col-5 bg-success text-end text-white">
                        <p className='m-0 fw-bold'>FREQUENCY -</p>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-5">
                        <div className='row'>
                            <div className='col-8'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Seller</th>
                                            <th scope="col">Volume</th>
                                            <th scope="col">Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cemQuadrant.map((item, key) => {
                                                return (
                                                <tr key={key}>
                                                    <td><a href="#" className='text-decoration-none' onClick={() => openSellerModal('cem',item.seller)}>{item.seller}</a></td>
                                                    <td>{formatNumberThousands(item.volume.toFixed(2))}</td>
                                                    <td>{item.percentage}</td>
                                                </tr> )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-4 text-center fw-bold'>
                                <p>Total</p>
                                <p>({volumeCEM}%)</p>
                                <p>{formatNumberThousands(volumeCEMQuantity)}</p>
                            </div>
                        </div>
                        <a href="#" className='h3' onClick={() => openQuadrantModal('cem')}>Cement / Particular</a>
                    </div>
                    <div className="col-1 text-center">
                        <p className="bg-success fw-bold text-white" style={{textOrientation: 'upright', writingMode: 'vertical-lr', height:'45%'}}>&nbsp; &nbsp; &nbsp; VOLUME -</p>
                    </div>
                    <div className="col-5">
                        <div className='row'>
                            <div className='col-4 text-center fw-bold'>
                                <p>Total</p>
                                <p>({volumeGOV}%)</p>
                                <p>{formatNumberThousands(volumeGOVQuantity)}</p>
                            </div>
                            <div className='col-8'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Seller</th>
                                            <th scope="col">Volume</th>
                                            <th scope="col">Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            govQuadrant.map((item, key) => {
                                                return (
                                                <tr key={key}>
                                                    <td><a href="#" className='text-decoration-none' onClick={() => openSellerModal('gov',item.seller)}>{item.seller}</a></td>
                                                    <td>{formatNumberThousands(item.volume.toFixed(2))}</td>
                                                    <td>{item.percentage}</td>
                                                </tr> )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <a href="#" className='h3' onClick={() => openQuadrantModal('gov')}><p className='text-end m-0'>Government & DOT</p></a>
                    </div>
                </div>
            </div>
        </div>
    }    

    const RenderDates = () => (
        <div className='form-group row' style={{maxWidth: '100%'}}>
        <div className='col-sm-2'>
            <label className='text-muted'>Start Date</label>
            <DatePicker
                selected={startDate}
                minDate={new Date('01/01/2022')}
                maxDate={new Date()}
                onChange={(date) => setStartDate(date)}
            />
        </div>
        <div className='col-sm-2'>
            <label className='text-muted'>End Date</label>
            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                minDate={startDate}
                maxDate={new Date()}
            />
        </div>
    </div>
    )

    return (
        <Layout
            title='Quadrants'
            description='Report'
        >
            <RenderDates/>
            {
                loading ? <RenderLoading/> : <RenderQuadrants/>
            } 

            <QuadrantModal showQuadrantModal={showQuadrantModal} toggleModal={toggleModal} selectedQuadrantType={selectedQuadrantType} data={selectedQuadrant}/>
            <SellerModal showSellerModal={showSellerModal} toggleSellerModal={toggleSellerModal} selectedSeller={selectedSeller} data={selectedQuadrant}/>



            <CSVLink data={tableCsvContent} headers={headers()} filename={"57concrete-quadrants-report.csv"}>
                Download report
            </CSVLink>
        </Layout>
    )
}

export default QuadrantsReport