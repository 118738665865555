import { Button, Col, Form, Input, Row, Select, notification } from 'antd'
import React, { useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
const { Option } = Select

const MechanicNew = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const duplicatedUserEmail = async (email) => {
        const url = api + '/useremail/' + email
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const createMechanic = async (data) => {
        const user = {
            name: data.name,
            email: data.email,
            password: data.password,
            role:'Mechanic'
        }

        try {
            let url = api + '/users' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, user)
            openNotification('success', 'Success', 'Mechanic created')
            setLoading(false)
            history.push('/mechaniclist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create user')
            console.error(err.message)
            return null
        }
    }
    
    const validateUser = async (values) => {
        const duplicadoEmail = await duplicatedUserEmail(values.email)
        
        if (duplicadoEmail) {
            openNotification('warning', 'Duplicated user', 'User alredy exist with Email')
            setLoading(false)
        } else {
            createMechanic(values)
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        validateUser(values)
    }

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please fill email'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password',
                        },
                        {
                            min: 5,
                            message: 'Password must have at least 5 characters'
                        }
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        {
                            min: 5,
                            message: 'Password must have at least 5 characters'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Mechanic'
        description='Create new mechanic'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default MechanicNew