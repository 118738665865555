import React, { useState, useEffect } from 'react'
import axios from 'axios'
import GoogleMapReact from 'google-map-react'
import Geocode from "react-geocode"
import Marker from './Marker'
import { api } from '../utils/Api'
import { Button, Col, Input, Row, notification } from 'antd'
const { Search } = Input

const AddressGeolocation2 = props => {  
    const [latitude, setLatitude] = useState(26.2787785)
    const [longitude, setLongitude] = useState(-98.4021818)
    const [locality, setLocality] = useState('')
    const [address, setAddress] = useState('')
    const [destLat, setDestLat] = useState('')
    const [destLng, setDestLng] = useState('')
    const [search, setSearch] = useState(props.search)
    const [distance, setDistance] = useState(null)
    const [duration, setDuration] = useState(null)
    const [name, setName] = useState('57concrete')
    const [apiNotification, contextHolder] = notification.useNotification()


    const googleApi = process.env.GOOGLE_MAP_API || "AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU"

    useEffect(() => {
        if(props.plant){
            getPlantInfo()
        }else{
            getCompanyInfo()
        }
    }, [])

    const getCompanyInfo = async () => {
        const url = api + '/company'        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setLatitude(data.latitude)
            setLongitude(data.longitude)            
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const getPlantInfo = async () => {
        const url = api + `/plant/${props.plant}`        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setLatitude(data.latitude)
            setLongitude(data.longitude)   
            setName(data.name)         
        } catch (err) {
            console.error(err)
            return null
        }
    }

    // address geocoding
    const getCoordinates = address => {
        Geocode.setApiKey(googleApi)
        Geocode.fromAddress(address).then(
            (response) => {
                const addressComponentes = response.results[0].address_components
                const { lat, lng } = response.results[0].geometry.location

                let locality = ''
                addressComponentes.forEach(item => {
                    const {types} = item
                    if (types[0] === 'locality') {
                        locality = item.long_name
                    }
                })
                setLocality(locality)
                setDestLat(lat)
                setDestLng(lng)
                calculateDistance(lat, lng)
            },
            (error) => {
                openNotification('error', 'Error', 'Address Not Found')
                console.error(error)
            }
          )        
    }

    // Distance to customer
    const calculateDistance = async (lat, lng) => {
        const ubicacion = {
            lat,
            lng
        }

        let url = ''
        if(props.plant){
            url = api + `/distanceplant/${props.plant}`
        }else{
            url = api + '/distance'
        }   

        try {
            const { data } = await axios.post(url, ubicacion)
            const distance = data.distance.text
            const duration = data.duration.text
            const recorrido = distance.split(' ')
            if (parseFloat(recorrido[0].trim()) > 100) {
                openNotification('error', 'Error', 'Address out of coverage')
            } else {
                setDistance(distance)
                setDuration(duration)
            }
        } catch (err) {
            console.error(err)
            return null
        }
    }

    const onClick = (event) => {
        Geocode.setApiKey(googleApi)
        Geocode.fromLatLng(event.lat, event.lng).then(
            (response) => {
                const formattedAddress = response.results[0].formatted_address
                const addressComponentes = response.results[0].address_components
                const lat = event.lat
                const lng = event.lng

                let locality = ''
                addressComponentes.forEach(item => {
                    const {types} = item
                    if (types[0] === 'locality') {
                        locality = item.long_name
                    }
                })
                setAddress(formattedAddress)
                setLocality(locality)
                setDestLat(lat)
                setDestLng(lng)
                calculateDistance(lat, lng)
            },
            (error) => {
                openNotification('error', 'Error', 'Address Not Recognized')
                console.error(error)
            }
        )  
    }

    const defaultMap = {
        center: {
            lat: latitude, 
            lng: longitude
        },
        zoom: 8
      }
     

    // Geocode Address
    const geoCode = (value) => {
        getCoordinates(value)
    }

    // pass data to parent
    const saveAddress = () => {
        const data = {
            address,
            destLat,
            destLng,
            distance,
            duration,
            locality,
        }
        props.parentCallback(data)
    }

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    // show text in map
    const AnyReactComponent = ({ text }) => <div>{text}</div>

    return (
        <Row gutter={16}>
            {
                search &&
                    <Col span={24}>
                        <Search
                        name='search'
                        placeholder="Search"
                        enterButton
                        onSearch={geoCode}
                        allowClear
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        size='large'
                        />
                    </Col>
            }
            <Col span={24} style={{ height: '60vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApi }}
                    defaultCenter={defaultMap.center}
                    defaultZoom={defaultMap.zoom}
                    onClick={onClick}
                    yesIWantToUseGoogleMapApiInternals
                >             
                    {
                        !props.single &&
                        <Marker 
                            lat={latitude}
                            lng={longitude}
                            color="red"
                        />
                    }
                    {
                        !props.single &&
                        <AnyReactComponent
                            lat={latitude}
                            lng={longitude}
                            text={name}
                        />
                    }           
                    {
                        destLat &&
                        <Marker
                            lat={destLat}
                            lng={destLng}
                            color="green"
                        />
                    }
                </GoogleMapReact>                    
            </Col>
            <Col span={4}>
                {
                    distance &&
                    <Button 
                        type='primary'
                        size='large'
                        onClick={saveAddress}
                    >
                        Save address
                    </Button>
                }                                 
            </Col>
            {contextHolder}
        </Row>
    )
}

export default AddressGeolocation2