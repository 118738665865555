import { Button, Col, Form, Input, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
import { CustomSpinner } from '../utils/CustomComponents'
const { Option } = Select

const DriverNew = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [plantOptions, setPlantOptions] = useState([])
    const [loadingForm, setLoadingForm] = useState(true)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getPlant()
    }, [])

    const getPlant = async () => {
        const url = api + '/plant'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let plantList = []
            data.map((item) => {
                plantList.push({
                    label: item.name,
                    value: item._id
                })
            })
            setPlantOptions(plantList)
            setLoadingForm(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const duplicatedUserPhone = async (phone) => {
        const url = api + `/userphone/+1${phone}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const createDriver = async (data) => {
        const user = {
            name: data.name,
            license: data.license,
            plant: data.plant,
            phone: `+1${data.phone}`,
        }

        try {
            let url = api + '/driver/create' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, user)
            openNotification('success', 'Success', 'Driver created')
            setLoading(false)
            history.push('/driverlist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create user')
            console.error(err.message)
            return null
        }
    }
    
    const validateUser = async (values) => {
        const duplicadoPhone = await duplicatedUserPhone(values.phone)
        
        if(duplicadoPhone){
            openNotification('warning', 'Duplicated user', 'User alredy exist with Phone')
            setLoading(false)
        }else {
            createDriver(values)
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        validateUser(values)
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
                prefix: '+1'
            }}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Phone'
                    name='phone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='License'
                    name='license'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill license',
                        },
                        {
                            max: 20,
                            message: 'License must be less than 20 characters'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label='Plant'
                        name='plant'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a plant'
                            }
                        ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select plant`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={plantOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Driver'
        description='Create new Driver'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm />}
        </Layout>
    )
}

export default DriverNew