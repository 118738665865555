import { Button, Checkbox, Col, Form, Input, Row, Select, notification } from 'antd'
import React, { useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api'
import axios from 'axios'
const { Option } = Select

const SellerNew = ({history}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    const duplicatedUserPhone = async (phone) => {
        const url = api + `/userphone/+1${phone}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const createSeller = async (data) => {
        const user = {
            name: data.name,
            email: data.email,
            password: data.password,
            phone: `+1${data.phone}`,
            role: 'Seller',
            corporateSales: data.corporateSales
        }

        try {
            let url = api + '/users' 
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.post(url, user)
            openNotification('success', 'Success', 'Seller created')
            setLoading(false)
            history.push('/sellerlist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to create user')
            console.error(err.message)
            return null
        }
    }
    
    const validateUser = async (values) => {
        const duplicadoPhone = await duplicatedUserPhone(values.phone)
        
        if(duplicadoPhone){
            openNotification('warning', 'Duplicated user', 'User alredy exist with Phone')
            setLoading(false)
        }else {
            createSeller(values)
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        validateUser(values)
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
                prefix: '+1'
            }}
            >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please fill email'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Phone'
                    name='phone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password',
                        },
                        {
                            min: 5,
                            message: 'Password must have at least 5 characters'
                        }
                        ]}
                        hasFeedback
                    >
                        <Input.Password size='large' />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        {
                            min: 5,
                            message: 'Password must have at least 5 characters'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="corporateSales"
                        valuePropName="checked"
                    >
                        <Checkbox>
                            Corporate Sales
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" loading={loading}>
                Create
            </Button>
        </Form>
    }

    return (
        <Layout
        title='New Seller'
        description='Create new Seller'
        type='medium'
        >
            {contextHolder}
            <CustomForm />
        </Layout>
    )
}

export default SellerNew