import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import JobstatusPicker from './JobStatusPicker'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import LoadJob from '../load/LoadJob'
import AddressJob from '../map/AddressJob'
import { DateTime } from "luxon"
import { api } from '../utils/Api'
import SubplantPicker from '../plant/SubplantPicker'
import JobToInvoiceModal from '../invoice/JobToInvoiceModal'

const JobItem = props => {
    const [comments, setComments] = useState(props.job.comments)
    const [comment, setComment] = useState('')
    const [loadSum, setLoadSum] = useState(null)
    const [showLoad, setShowLoad] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [jobStatus, setJobStatus] = useState(props.job.jobStatus)
    const [jobStatusPrev, setJobStatusPrev] = useState(props.job.jobStatus)
    const [jobStatus2, setJobStatus2] = useState('')
    const [jobTime, setJobTime] = useState()
    const [showMap, setShowMap] = useState(false)
    const [approved, setApproved] = useState(props.job.approved)
    const [restrict, setRestrict] = useState(null)
    const [confirm, setConfirm] = useState(null)
    const [creditHold, setCreditHold] = useState(props.job.hold)
    const [development, setDevelopment] = useState(props.job.buildDevelopment)
    const [logisticConfirmation, setLogisticConfirmation] = useState(props.job.logisticConfirmation)
    const [subplant, setSubplant] = useState(props.job.subplant)
    const [showInvoiceModal, setShowInvoiceModal] = useState(false)
    const [showErrorInvoice, setShowErrorInvoice] = useState(false)
    const [fiber, setFiber] = useState(props.job.fiberQuantity && props.job.fiberQuantity > 0)
    const [readyToBeScheduledDate, setReadyToBeScheduledDate] = useState(props.job.readyToBeScheduledDate || null)

    useEffect(() => {
        getLoadData()
        setConfirm(props.job.approved)
        isApproved()
        manageJobStatus()
    }, [])
    
    const manageJobStatus = () => {
        let status = props.job.jobStatus
        if(status === 'ok'){
            setJobStatus2('Poured')
        }else if(status === 'Active'){
            setJobStatus2('Pouring')
        }else if(status === 'Confirm'){
            setJobStatus2('A/R Confirmed')
        }else if(status === 'Ready to Load'){
            setJobStatus2('Ready to be scheduled')
        }
    }

    const handleNewModal = () => {
        setShowLoad(!showLoad)
        getLoadData()
    }

    const handleComment = () => {
        setShowComment(!showComment)
    }

    const handleInvoice = () => {
        setShowInvoiceModal(!showInvoiceModal)
    }

    const toggleErrorInvoice = () => {
        setShowErrorInvoice(!showErrorInvoice)
    }

    const isApproved = () => {
        const tipoPago = props.job.customer.paymentType
        const validado = props.job.approved

        if (localStorage.getItem('role') === 'Finance' ||
            tipoPago !== 'PrePay' || validado
        ) {
            setRestrict(false)
        } else {
            setRestrict(true)
        }

        // regla para habilitar el confirm
        if (validado) {
            setConfirm(true)
        } else {
            if (localStorage.getItem('role') === 'Finance' ||
                tipoPago !== 'Credit') {
                setConfirm(true)
            } else {
                setConfirm(false)
            }
        }

        // Excepcion i el cliente esta en credit hold no dejar editar nada
        if (creditHold) {
            setConfirm(false)
        }
    }

    const onChangeJobStatus = async(selectedOption) => {
        if (selectedOption) {
            setJobStatus(selectedOption.value)
            setJobStatus2(selectedOption.label)
            setApproved(true)
            setConfirm(true)

            if(selectedOption.value === 'Ready to Load'){
                setReadyToBeScheduledDate(new Date())
            }

            if(selectedOption.value === "Operations done (AR)"){
                const exist = await getInvoiceExistance()
                if(!exist) handleInvoice()
            }
        }
    }

    const onChangeJobSubplant = selectedOption => {
        if (selectedOption) {
            setSubplant(selectedOption.label)
        }
    }

    const showModal = e => {
        e.preventDefault()
        setShowMap(true)
    }
    
    const hideModal = e => {
        setShowMap(false)
    }

    const onChangeCreditHold = e => {
        setCreditHold(!creditHold)
        setConfirm(!confirm)
        setJobStatus('Confirm')
    }

    // link job status with color
    const selectColor = estatus => {
        switch (estatus) {
            case 'Trouble':
                return ('#7a7977')
            case 'Inactive':
                return ('#ffffff')
            case 'ok':
                return ('#7ad765')
            case 'Ready to Load':
                return ('#208928')
            case 'Ready to be scheduled':
                return ('#208928')
            case 'Active':
                return ('#fff569')
            case 'Date moved':
                return ('#d9a809')
            case 'Operations done (AR)':
                return ('#fce0a2')
            case 'Company cancellation':
                return ('#e087fa')
            case 'Customer cancellation':
                return ('#ad87fa')
            case 'Quote':
                return ('#c3c2c0')
            case 'Confirm':
                return ('#31b9f7')
            case 'Scheduled':
                return ('#e3495d')
            default:
                return ('#fff569')
        }
    }

    const setBackgroudColor = () => {
        if(props.job.logisticDate){
            return ('#b9d3fa')
        }else{
            return ('white')
        }
    }

    const getInvoiceExistance = async () => {
        const url = api + '/jobinvoice/' + props.job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.get(url)
            return true
        } catch (err) {
            return false
        }
    }

    // job list for selected date
    const getLoadData = async () => {
        const url = api + '/load/' + props.job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        const urlJob = api + '/job/' + props.job._id

        try {
            const { data } = await axios.get(url)
            const suma = data.map(viaje => viaje.yards).reduce((acc, acum) => acc + acum, 0)
            setLoadSum(Math.round(suma * 100) / 100)

            const updateJob = {
                loadYD3: Math.round(suma * 100) / 100
            }

            await axios.patch(urlJob, updateJob)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // job list for selected date
    const updateLoad = async (status) => {
        const url = api + '/load/' + props.job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            const body = {
                status: status
            }
            if(data.length !==0){
                data.forEach(async load => {
                    const urlUpdate = api + '/load/' + load._id
                    await axios.patch(urlUpdate, body)
                });
            }

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // update job attributes
    const saveJob = async (type) => {

        const exist = await getInvoiceExistance()

        if(!exist && jobStatus === 'Operations done (AR)'){
            toggleErrorInvoice()
            return
        }

        if(jobStatus === 'Confirm' || jobStatus === 'Active'){
            updateLoad('waiting')
        }
        if(jobStatus === 'Operations done (AR)' || jobStatus === 'ok'){
            updateLoad('done')
        }
        
        let newJob = {
            jobStatus,
            loadYD3: loadSum,
            approved,
            updatedBy: localStorage.getItem('userId'),
            hold: creditHold,
            approved: confirm,
            subplant: subplant,
        }

        if (jobTime) {
            newJob.scheduledDate = jobTime
        }

        if (confirm) {
            newJob.approved = true
        }

        if (type === 'logistic'){
            newJob.logisticConfirmation = !logisticConfirmation
            newJob.logisticComment = comment
        }

        if(jobStatus !== jobStatusPrev){
            newJob.jobStatusHistory = {
                user: localStorage.getItem('userId'),
                jobStatus: jobStatus
            }
        }

        if(jobStatus === 'Ready to Load'){
            newJob.readyToBeScheduledDate = readyToBeScheduledDate
        }


        const url = api + '/job/' + props.job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        const urlLog = api + '/jobhistory'

        const antiguo = {
            funding: props.job.funding,
            taxExemptJob: props.job.taxExemptJob,
            contractorType: props.job.contractorType,
            ownerState: props.job.ownerState,
            suretyState: props.job.suretyState,
            balance: props.job.balance,
            propsStatus: props.job.jobStatus,
            slump: props.job.slupm,
            paid: props.job.paid,
            isPhoneQuote: props.job.isPhoneQuote,
            hasInvoice: props.job.hasInvoice,
            approved: props.job.approved,
            customer: props.job.customer,
            name: props.job.name,
            loadYD3: props.job.loadYD3,
            targetYD3: props.job.targetYD3,
            shipAddress: props.job.shipAddress,
            propsForeman: props.job.jobForeman,
            foremanPhone: props.job.foremanPhone,
            apContact: props.job.apContact,
            apPhone: props.job.apPhone,
            scheduledDate: props.job.scheduledDate,
            destinationMinutes: props.job.destinationMinutes,
            comments: props.job.comments,
            po: props.job.po,
            jobDuration: props.job.jobDuration,
            unitPrice: props.job.unitPrice,
            jobPoor: props.job.jobPoor,
            jobType: props.job.jobType,
            number: props.job.number,
            seller: props.job.seller,
            psi: props.job.psi,
            destinationTime: props.job.destinationTime,
            distance: props.job.distance,
            latitude: props.job.latitude,
            longitude: props.job.longitude,
            paymentType: props.job.paymentType,
            firstShipementDate: props.job.firstShipementDate,
            scheduledTime: props.job.scheduledTime,
            createdBy: localStorage.getItem('userId'),
            updatedBy: localStorage.getItem('userId')
        }

        try {
            await axios.patch(url, newJob)
            await axios.post(urlLog, antiguo)
            if(type === 'normal') props.wic()
            if(jobStatus === 'Scheduled'){
                if(props.job.jobStatus !== jobStatus){
                    props.action(true)
                }
            }else if(props.job.jobStatus === 'Scheduled'){
                if(props.job.jobStatus !== jobStatus){
                    props.action(true)
                }
            }
            
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const changeTime = e => {
        const fecha = DateTime.fromISO(props.job.scheduledDate)
        const anio = fecha.year
        const mes = fecha.month
        const dia = fecha.day
        const [hora, minuto] = e.target.value.split(':')
        const newDate = new Date(anio, mes - 1, dia, hora, minuto, 0, 0)
        setJobTime(newDate)
    }

    const manageConfirm = e => {
        setConfirm(!confirm)
    }

    const manageLogisticConfirmation = (e) => {
        e.preventDefault()
        saveJob('logistic');
        setLogisticConfirmation(!logisticConfirmation)
        handleComment()
    }

    return (
        <tr
            className='small'
            bgcolor={setBackgroudColor()}
        >
            <td>
                {
                    !creditHold &&
                    <input name="confirm"
                        type="checkbox"
                        checked={confirm ? confirm : props.job.approved}
                        onChange={manageConfirm}
                    />
                }
                {
                    creditHold &&
                    <div>
                        <input name="onlyRead"
                            type="checkbox"
                            checked={confirm}
                            onChange={manageConfirm}
                        />
                        <label>
                            <strong>Credit on Hold</strong>
                        </label>
                    </div>
                }

            </td>
            <td>
                <Link to={'/job/' + props.job._id}>
                    {
                        props.job.customer ? props.job.customer.customerNumber + ' ' + props.job.customer.name + ' ph: ' + props.job.foremanPhone : 'No customer assigned!'
                    }
                </Link>
            </td>
            <td>
                {props.job.number}
            </td>
            <td>
                {
                    subplant ? subplant : props.job.subplant
                }
                <br />
                <SubplantPicker plant={props.job.plant} action={onChangeJobSubplant} value={subplant} isRequired={false} />
            </td>
            <td style={{ fontSize: '15px' }}>
                {new Date(props.job.scheduledDate).toLocaleTimeString([], { timeStyle: 'short' })}
                <input
                    type='time'
                    id='time'
                    name='time'
                    onChange={changeTime}
                />
            </td>
            <td>
                <Link onClick={showModal}
                    to='#'
                >
                    {
                        props.job.shipAddress
                    }
                </Link>
                <Modal show={showMap} animation={false} onHide={hideModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{props.job.shipAddress}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddressJob
                            lat={props.job.latitude}
                            lng={props.job.longitude}
                            address={props.job.shipAddress}
                            plant={props.job.plant}
                        />
                    </Modal.Body>
                </Modal>
            </td>
            <td>
                {
                    loadSum
                }
                <br />
                {
                    ((jobStatus === 'Inactive' && confirm) ||
                        (jobStatus === 'Confirm' && confirm) ||
                        (jobStatus === 'Active' && confirm) ||
                        (jobStatus === 'ok' && confirm))
                    &&
                    <button
                        className='button btn-primary'
                        onClick={handleNewModal}
                    >+
                    </button>
                }
                <Modal show={showLoad} onHide={handleNewModal} animation={false} size='lg' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className='col-sm-12'>
                                Load YD3. JobID = {props.job.number}
                            </div>
                            {
                            (localStorage.getItem('email') === "mv@57concrete.com"
                            || localStorage.getItem('email') === "ec@57concrete.com"
                            || localStorage.getItem('email') === "fc@57concrete.com"
                            || localStorage.getItem('email') === "bp@57concrete.com"
                            || localStorage.getItem('email') === "vm@57concrete.com"
                            || localStorage.getItem('email') === "jp@57concrete.com"
                            || localStorage.getItem('email') === "eliud@gmail.com"
                            ) &&
                            <div className='col-sm-12'>
                                <button className={`btn ${logisticConfirmation ? 'btn-success' : 'btn-danger'}`} onClick={handleComment}>Logistics confirmation</button>
                            </div>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadJob pcb={props.pcb} job={props.job} />
                    </Modal.Body>
                </Modal>
                <Modal show={showComment} onHide={handleComment} animation={false} size='sm' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Add comment to logistic confirmation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className='form' onSubmit={manageLogisticConfirmation}>
                            <div className='form-group row'>
                                <div className='col-sm-12'>
                                    <input
                                        type='text'
                                        name='comment'
                                        placeholder='Comment'
                                        value={comment}
                                        required
                                        className='form-control'
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </div>
                                <div className='col-sm-12'>
                                    <button type='submit' className="btn btn-success">Save</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </td>
            <td>
                {
                    props.job.balance ?
                        (typeof props.job.targetYD3 === "string" ? props.job.targetYD3 : props.job.targetYD3.toString()) + ' + ' + props.job.balance :
                        (typeof props.job.targetYD3 === "string" ? props.job.targetYD3 : props.job.targetYD3.toString()) + ' + 0'
                }
            </td>
            <td>
                {
                    props.job.destinationTime ?
                        props.job.destinationTime :
                        props.job.destinationMinutes
                }
            </td>
            <td>
                {props.job.psi}
            </td>
            <td>
                {props.job.slump}
            </td>
            <td>
                <input name="fiber"
                    type="checkbox"
                    checked={fiber}
                    onChange={e => setFiber(!e.target.checked)}
                />
                {
                    props.job.fiberQuantity &&
                    <label>
                        <strong>{props.job.fiberQuantity}</strong>
                    </label>
                }
            </td>
            <td>
                {
                    props.job.jobPoor + ' / ' + props.job.jobType
                }
            </td>
            <td style={{ background: selectColor(jobStatus ? jobStatus : props.job.jobStatus) }} >
                {
                    jobStatus2 ? jobStatus2 : props.job.jobStatus
                }
                <br />
                {
                    (!restrict && confirm) &&
                    <JobstatusPicker action={onChangeJobStatus} />
                }
                <Modal show={showInvoiceModal} onHide={handleInvoice} animation={false} size='xl' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Invoice Generator
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <JobToInvoiceModal jobId={props.job._id} pcb={handleInvoice}/>
                    </Modal.Body>
                </Modal>
                <Modal show={showErrorInvoice} onHide={toggleErrorInvoice} animation={false} size='sm' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Save Warning
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You can't save changes until invoice is generated</p>
                    </Modal.Body>
                </Modal>
            </td>
            <td>
                {
                    props.job.seller ? props.job.seller.name : props.job.associateName
                }
            </td>
            <td>        
                {}        
                {
                   readyToBeScheduledDate ?  `${new Date(readyToBeScheduledDate).toLocaleDateString()} - ${new Date(readyToBeScheduledDate).toLocaleTimeString([], { timeStyle: 'short' })}` : '-'
                }
            </td>
            {
                (localStorage.getItem('jobHold') === "true" ? true : false) &&
                <td>
                    <div>
                        <input name="creditHold"
                            type="checkbox"
                            checked={creditHold}
                            onChange={onChangeCreditHold}
                        />
                        {
                            creditHold &&
                            <label>
                                <strong>Job Hold</strong>
                            </label>
                        }
                    </div>
                </td>
            }
            <td>
                {comments}
            </td>
            <td>
                {
                    (localStorage.getItem('email') === "mv@57concrete.com" ||
                        localStorage.getItem('email') === "ec@57concrete.com" ||
                        localStorage.getItem('email') === "eliud@gmail.com" ||
                        confirm
                    ) &&
                    <button className="btn btn-success" onClick={() => saveJob('normal')}>Save</button>
                }
            </td>
        </tr>
    )
}

export default JobItem