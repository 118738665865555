import { Component } from 'react';
import axios from 'axios';
import { api } from '../utils/Api';
import GoogleMap from './GoogleMap';
import './GoogleMap.css';
import MarkerComponent from './MarkerComponent';
import { CustomSpinner } from '../utils/CustomComponents';
import { Button } from 'antd';
import {
    SyncOutlined
} from '@ant-design/icons'
class SingleMap extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            truck: {},
            center: {lat: 26.2787785, lng: -98.4021818},
            googleApi: process.env.GOOGLE_MAP_API || "AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU",
            id: this.props.id,
            loading: true,
        };
    }
  
    getTruck = async () => {
        const url = api + `/trucksinglemap/${this.state.id}`        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)   
            let centerCoordinates = {}
            let singleTruck = {}

            centerCoordinates = {lat: data.latitude, lng: data.longitude}

            singleTruck = {
                number: data.number,
                latitude: data.latitude,
                longitude: data.longitude,
                show: false,
                hover: false,
                direction: data.direction,
                plant: data.plant,
            }

            this.setState({
                truck: singleTruck,
                center: centerCoordinates,
                loading: false
            })

        } catch (err) {
            console.error(err)
            return null
        }
    }
  
    componentDidMount() {
        this.getTruck()
    }
  
    onChildMouseEnter = (key) => {
        this.setState({
            truck: {...this.state.truck, hover: true, show: true}
        })
    }

    onChildMouseLeave = (key) => {
        this.setState({
            truck: {...this.state.truck, hover: false, show: false}
        })
    }

    RenderRefreshButton = () => {
        return <Button 
        size='large' 
        type='primary' 
        danger
        style={{width: 60, backgroundColor: 'green' }} 
        onClick={e => {
            this.setState({
                loading: true
            })
            this.getTruck()
        }
        } 
        icon={<SyncOutlined />}
        loading={this.state.loading}
        disabled={this.state.loading}
        ></Button>
    }


    render() {
    const { truck, center, googleApi } = this.state;
  
      return (
        <div>
            {
                this.state.loading ? 
                <CustomSpinner />
                :
                <GoogleMap
                    defaultZoom={13}
                    defaultCenter={center}
                    bootstrapURLKeys={{ key: googleApi }}
                    onChildMouseEnter={this.onChildMouseEnter}
                    onChildMouseLeave={this.onChildMouseLeave}
                    >
                    <MarkerComponent
                        key={truck.number}
                        lat={truck.latitude}
                        lng={truck.longitude}
                        show={truck.show}
                        hover={truck.hover}
                        truck={truck}
                        />
                </GoogleMap>
            }
            {this.RenderRefreshButton()}
        </div>
      );
    }
}

export default SingleMap;