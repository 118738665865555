import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api';
import axios from 'axios';
import { CustomSpinner } from '../utils/CustomComponents'
const { Option } = Select

const Driver = (props) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})
    const [plantOptions, setPlantOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(true)
    const [phone, setPhone] = useState('')
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getPlant()
        getDriver()
    }, [])
    
    const getPlant = async () => {
        const url = api + '/plant'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let plantList = []
            data.map((item) => {
                plantList.push({
                    label: item.name,
                    value: item._id
                })
            })
            setPlantOptions(plantList)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getDriver = async () => {
        const url = api + '/driver/get/' + props.match.params.id        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setInitialValues({
                ...initialValues,
                name: data.name,
                phone: data.phone ? data.phone.substring(2) : '',
                license: data.license,
                plant: data.plant,
                subplant: data.subplant,
                workingHours: data.workingHours,
                comment: data.comment,
                prefix : '+1'
            })
            setPhone(data.phone)
            setLoadingForm(false)
        } catch (err) {
            setLoadingForm(false)
            openNotification('error', 'Error', 'Error while trying to get user')
            console.error(err.message)
            return null
        }
    }

    const deleteDriver = async () => {
        setLoading(true)

        const url = api + '/driver/delete/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const body = { active: false }
            await axios.patch(url, body)
            openNotification('info', 'Info', 'Driver Deleted')
            setLoading(false)
            props.history.push('/driverlist')
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const duplicatedUserPhone = async (phone) => {
        const url = api + `/userphone/+1${phone}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateDriver = async (data) => {
        const user = {
            name: data.name,
            phone: '+1'+data.phone,
            plant: data.plant,
            subplant: data.subplant,
            license: data.license,
            comment: data.comment
        }

        if(data.password !== undefined && data.password !== null && data.password !== ''){
            user.password = data.password
        }

        try {
            let url = api + '/driver/update/' + props.match.params.id  
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, user)
            openNotification('success', 'Success', 'Driver updated')
            setLoading(false)
            props.history.push('/driverlist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to get user')
            console.error(err.message)
            return null
        }
    }
    
    const validateUser = async (values) => {
        if(values.phone === phone){
            updateDriver(values)
        }else{
            const duplicadoPhone = await duplicatedUserPhone(values.phone)
            
            if (duplicadoPhone) {
                openNotification('warning', 'Duplicated user', 'User alredy exist with Phone')
                setLoading(false)
            }else {
                setLoading(false)
                updateDriver(values)
            }
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        validateUser(values)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{
              width: 70,
            }}
          >
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Phone'
                    name='phone'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill phone',
                        },
                        {
                            len: 10,
                            max: 10,
                            message: 'Phone number must have 10 numbers'
                        }
                    ]}
                    >
                        <Input size='large' addonBefore={prefixSelector} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='License'
                    name='license'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill license',
                        },
                        {
                            max: 20,
                            message: 'License must be less than 20 characters'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Plant'
                    name='plant'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a plant'
                        }
                    ]}
                    >
                        <Select
                        showSearch
                        placeholder={`Select plant`}
                        optionFilterProp="children"
                        size='large'
                        filterOption={filterOption}
                        options={plantOptions}
                        style={{
                            width: '100%'
                        }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Working Hours'
                    name='workingHours'
                    rules={[
                        {
                            type: 'number',
                        },
                    ]}
                    >
                        <InputNumber
                            size='large'
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Comment'
                    name='comment'
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            {
                (localStorage.getItem('email') === 'eliud@gmail.com' ||
                localStorage.getItem('email') === 'ec@57concrete.com') &&
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="password"
                            label="Change Password"
                            rules={[
                            {
                                min: 5,
                                message: 'Password must have at least 5 characters'
                            }
                            ]}
                            hasFeedback
                        >
                            <Input.Password size='large' />
                        </Form.Item>
                    </Col>
                </Row>
            }
            <Row gutter={16}>
                <Col>
                    <Button type="primary" htmlType="submit" loading={loading} size='large'>
                        Save
                    </Button>
                </Col>
                {/* <Col>
                    <Popconfirm
                        title="Delete the user"
                        description="Are you sure to delete this user?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={deleteDriver}
                    >
                        <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                    </Popconfirm>
                </Col> */}
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Driver'
        description='Edit Driver'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm />}
        </Layout>
    )
}

export default Driver