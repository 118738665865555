import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DriverPicker from '../driver/DriverPicker'
import TruckPicker from "../truck/TruckPicker";
import { api } from '../utils/Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFilePdf,
} from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory } from 'react-router-dom';
import formatDate from '../utils/FormatDate';

const LoadJob = props => {
    const [loads, setLoads] = useState([])
    const [loadSum, setLoadSum] = useState('')
    const [driver, setDriver] = useState('')
    const [driverId, setDriverId] = useState('')
    const [newLoad, setNewLoad] = useState('')
    const [loadChange, setLoadChange] = useState('')
    const [deliveryTicket, setDeliveryTicket] = useState('')
    const [orderCode, setOrderCode] = useState('')
    const [driverSelected, setDriverSelected] = useState(true)
    const [trucks, setTrucks] = useState([])
    const [truckNumber, setTruckNumber] = useState('')
    const [sequence, setSequence] = useState()
    const [newTruck, setNewTruck] = useState({})
    let history = useHistory()

    useEffect(() => {
        getLoadData()
        getDrivers()
    }, [loadChange])

    const onChangeDriver = selectedDriver => {
        if (selectedDriver) {
            const camion = trucks.find(trompo => trompo._id === selectedDriver.value)
            setTruckNumber(camion.truckNum)
            setDriver(selectedDriver.label)
            setDriverId(selectedDriver.value)
        }
    }

    const onChangeTruck = selectedTruck => {
        if (selectedTruck) {
            setTruckNumber(selectedTruck.label)
            setNewTruck(selectedTruck)
        }
    }

    const getLoadData = async () => {
        const url = api + '/load/' + props.job._id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            setLoads(data)
            const suma = data.map(viaje => viaje.yards).reduce((acc, acum) => acc + acum, 0)
            setLoadSum(suma)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getDrivers = async () => {
        const url = api + '/driver/list'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            const chofer = data.map(chof => {
                const truckNum = chof.truck ? chof.truck.number : '0'
                return {
                    _id: chof._id,
                    phone: chof.phone,
                    diverName: chof.name,
                    truckNum,
                }
            })
            setTrucks(chofer)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }


    const editDriverTruck = async () => {
        const url = api + '/driver/update/' + driverId
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        const driverNewTruck = {
            truck: {
                _id: newTruck.value,
                number: newTruck.label
            }
        }
        try {
            await axios.patch(url, driverNewTruck)
            getDrivers()
        } catch (error) {
            console.error(error.message)
            return null
        }
    }

    // delete load
    const deleteLoad = async (load) => {
        const url = api + `/load/${load._id}`
        const urlHistory = api + `/history`
        const urlJob = api + `/job/${props.job._id}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const bodyHistory = {
                date: new Date(),
                customer: props.job.customer._id,
                user: localStorage.getItem('userId'),
                type: 'Load',
                yards: load.yards,
                number: load.deliveryTicket
            }
            await axios.post(urlHistory, bodyHistory)
            await axios.delete(url)
            getLoadData()

            const job = {
                loadYD3: loadSum
            }

            const currentSum = parseFloat(localStorage.getItem('loadSum'))
            const newSum = currentSum - load.yards
            const result = Math.round(newSum * 100) / 100
            localStorage.setItem('loadSum', result)

            await axios.patch(urlJob, job)
            props.pcb(-load.yards)

        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // Send SMS to company contact and foreman
    const sendSMS = async (phone) => {
        const url = api + '/smsmessagetxt'

        // Para el botón "Notify" en job appointment usar este texto:
        // Job id $number appointment is scheduled at $date $time
        // "notifyTimeStamp" (date) se guarda esa fecha al momento de dar clic, 
        // mostrar este timestamp en otra columna.
        // Para el boton "Close" en job appointment onClick jobStatus = "ok" y ya no permitir
        // cambios. Nueva propiedad en job: "closeAppointment" (boolean)

        let msg = `Your order with ${newLoad} yards has been loaded. `
        msg += `Ticket: ${deliveryTicket}. Driver: ${driver}.`

        if (truckNumber !== '0') {
            msg += ' Truck No. ' + truckNumber
        }

        msg += ' #57concrete'

        const smsBody = {
            phone,
            msg
        }

        try {
            await axios.post(url, smsBody)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }


    // save new load
    const saveLoadData = async (sequenceNumber) => {
        const url = api + '/load'
        const urlJob = api + `/job/${props.job._id}`
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        const urlpost = api + '/ticket'

        const loadDate = new Date()

        const carga = {
            yards: newLoad,
            driver: driver,
            job: props.job._id,
            date: loadDate,
            deliveryTicket,
            orderCode,
            truckNumber,
            sequence: sequenceNumber,
            createdBy: userId,
            updatedBy: userId
        }

        const numJob = props.job.number.toString()
        const ticket = {
            "jobNumber": numJob,
            "truckName": truckNumber,
            "orderQuantity": newLoad,
            "orderNumber": orderCode,
            "ticketNumber": deliveryTicket,
            "vendorTicketId": "100",
            "vendorOrderId": orderCode,
            "streetAddress": props.job.shipAddress,
            "city": props.job.shipCity,
            "state": 'TX',
            "latitude": props.job.latitude,
            "longitude": props.job.longitude,
            "primaryProduct": props.job.psi,
            "customerName": props.job.customer.customerNumber + ' ' + props.job.customer.name,
            "loadSize": newLoad,
            "jobAddress": props.job.shipAddress,
            "quantity": newLoad.toString()
        }

        const currentSum = parseFloat(localStorage.getItem('loadSum'))
        setNewLoad(newLoad)
        const newSum = currentSum + parseFloat(newLoad)
        const result = Math.round(newSum * 100) / 100

        localStorage.setItem('loadSum', result)

        try {
            await axios.post(url, carga)
            setLoadChange('yes')
            props.pcb(parseFloat(newLoad))
            const job = {
                loadYD3: parseFloat(localStorage.getItem('loadSum'))
            }
            await axios.patch(urlJob, job)
            getLoadData()
            const digitalResponse = await axios.post(urlpost, ticket)

        } catch (err) {
            console.error(err)
            return null
        }
    }

    const DrawLoad = () => {
        if (loads.length > 0) {
            return loads.map((ld) => {
                const hyperlink = `http://ec2-3-85-198-38.compute-1.amazonaws.com/${ld._id}`
                return <tr key={ld._id}>
                    <td>{ld.driver}</td>
                    <td>{ld.truckNumber} </td>
                    <td>{ld.yards}</td>
                    <td>{new Date(ld.date).toLocaleTimeString()}</td>
                    <td>{ld.deliveryTicket}</td>
                    <td> {ld.orderCode} </td>
                    <td> {ld.sequence ? ld.sequence : '-'} </td>
                    {/* <td><p onClick={() => history.push(`/e-ticket/${ld._id}`)}>eticket</p></td> */}
                    <td><a href={hyperlink} target='_blank' className='btn btn-primary'><FontAwesomeIcon icon={faFilePdf} /></a></td>
                    <td>
                        <button 
                        className='btn btn-danger' 
                        onClick={() => deleteLoad(ld)}
                        disabled={
                            (localStorage.getItem('email') === "vm@57concrete.com" ||
                            // localStorage.getItem('email') === "ji@57concrete.com" ||
                            localStorage.getItem('email') === "fc@57concrete.com" ||
                            localStorage.getItem('email') === "jp@57concrete.com" ||
                            localStorage.getItem('email') === "ec@57concrete.com" ||
                            localStorage.getItem('email') === "eliud@gmail.com"
                            ) ? false : true
                        }
                        >-</button>
                    </td>
                </tr>
            })
        } else {
            return (
                <tr>
                    <td colSpan="9" style={{ 'textAlign': 'center' }}>
                        No load yet
                    </td>
                </tr>
            )
        }
    }

    const validateDriver = () => {
        if (!driver) {
            setDriverSelected(false)
            return false
        } else {
            setDriverSelected(true)
            return true
        }
    }

    /*
        Send SMS messages only to registered phones
    */
    const sendMessages = () => {
        if (props.job.customer.contactPhone) {
            sendSMS(props.job.customer.contactPhone)
        }
        if (props.job.foremanPhone) {
            sendSMS(props.job.foremanPhone)
        }
        if (props.job.pmPhone) {
            sendSMS(props.job.pmPhone)
        }
        if (props.job.supervisorPhone) {
            sendSMS(props.job.supervisorPhone)
        }

    }

    // check sequence
    const checkSequence = async () => {

        const startDate = new Date()

        const url = api + `/loadsequence?startDate=${formatDate(startDate)}&sequence=${sequence}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.post(url)

            if(data.loadId){
                const body = {
                    sequence : data.newSequence,
                    updatedBy: localStorage.getItem('userId')
                }
                updateLoad(data.loadId, body)
                saveLoadData(data.sequence)
                editDriverTruck()
                sendMessages()
            }else{
                saveLoadData(data.sequence)
                editDriverTruck()
                sendMessages()
            }
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    // upload load sequence
    const updateLoad = async (loadId, body) => {
        const url = api + `/load/${loadId}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.patch(url, body)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const onSubmit = e => {
        e.preventDefault()

        // checkSequence()

        if (validateDriver()) {
            // saveLoadData()
            checkSequence()
            // editDriverTruck()
            // sendMessages()
        }
    }

    return (
        <div>
            Load Total: {Math.round(loadSum * 100) / 100}
            <table className='table'>
                <thead>
                    <tr>
                        <th>Driver</th>
                        <th>Truck</th>
                        <th>Load</th>
                        <th>Date</th>
                        <th>Ticket</th>
                        <th>OrderCode</th>
                        <th>Sequence</th>
                        <th>E-Ticket</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <DrawLoad />
                </tbody>
            </table>
            <form className='form' onSubmit={onSubmit}>
                <div className='form-group row'>
                    <div className='col-sm-6'>
                        <DriverPicker action={onChangeDriver} />
                        <br />
                        <input
                            type='number'
                            id='load'
                            value={newLoad}
                            name='load'
                            required
                            className='form-control'
                            placeholder='Enter load'
                            step='0.1'
                            onChange={(e) => setNewLoad(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <TruckPicker action={onChangeTruck} />
                        <br />
                        <input
                            type='text'
                            name='deliveryTicket'
                            placeholder='Delivery Ticket'
                            required
                            value={deliveryTicket}
                            className='form-control'
                            onChange={(e) => setDeliveryTicket(e.target.value)}
                        />
                    </div>
                </div>
                <br />
                <div className='form-group row'>
                    <div className='col-sm-6'>
                        <input
                            type='text'
                            name='orderCode'
                            placeholder='Order Code'
                            required
                            value={orderCode}
                            className='form-control'
                            onChange={(e) => setOrderCode(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <input
                            type='number'
                            min={1}
                            name='sequence'
                            placeholder='Sequence'
                            required
                            value={sequence}
                            className='form-control'
                            onChange={(e) => setSequence(e.target.value)}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <button type='submit' /*onClick={onSubmit}*/ className="btn btn-success">+</button>
                    </div>
                </div>
            </form>
            {
                !driverSelected &&
                <div>
                    You must select a driver
                </div>
            }
        </div>
    )
}

export default LoadJob