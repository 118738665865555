// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import GoogleMapReact from 'google-map-react'
// import Marker from './Marker'
// import { api } from '../utils/Api'
// import Layout from '../layout/Layout'
// import GoogleMap from './GoogleMap'
// const TruckMap = props => {  
//     const [latitude, setLatitude] = useState(26.2787785)
//     const [longitude, setLongitude] = useState(-98.4021818)
//     const [coordiantes, setCoordinates] = useState([])
//     const [center, setCenter] = useState({lat: 26.2787785, lng: -98.4021818})
//     const [zoom, setZoom] = useState(10)
//     const googleApi = process.env.GOOGLE_MAP_API || "AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU"
//     useEffect(() => {
//         getTruckList()
//     }, [])
//     const getTruckList = async () => {
//         const url = api + '/trucklist'        
//         const token = localStorage.getItem('token')
//         axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
//         try {
//             const { data } = await axios.get(url)   
//             let coordinatesTrucks = []
//             data.map((item, index) => {
//                 if(item.latitude && item.longitude){
//                     if(index === 0){
//                         setCenter({lat: item.latitude, lng: item.longitude})
//                     }
//                     coordinatesTrucks.push({
//                         number: item.number,
//                         latitude: item.latitude,
//                         longitude: item.longitude,
//                         active: false
//                     })
//                 }
//             })  
//             setCoordinates(coordinatesTrucks)     
//         } catch (err) {
//             console.error(err)
//             return null
//         }
//     }
//     // show text in map
//     const AnyReactComponent = ({ text, active }) => <div style={{fontWeight: 'bold'}}>
//         {text}
//     </div>
//     const RenderComponent = () => (
//         <div>
//             <div style={{ height: '60vh', width: '100%' }}>
//                 <GoogleMap
//                     bootstrapURLKeys={{ key: googleApi }}
//                     defaultCenter={center}
//                     defaultZoom={zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                 >
//                 </GoogleMap>
//                 {/* <GoogleMapReact
//                     bootstrapURLKeys={{ key: googleApi }}
//                     defaultCenter={center}
//                     defaultZoom={zoom}
//                     yesIWantToUseGoogleMapApiInternals
//                 >             
//                     {
//                         coordiantes.map((coodinate) => (
//                             <Marker 
//                                 lat={coodinate.latitude}
//                                 lng={coodinate.longitude}
//                                 color="red"
//                             />
//                         ))
//                     }
//                     {
//                         coordiantes.map((coordinate) => (
//                             <AnyReactComponent 
//                                 lat={coordinate.latitude}
//                                 lng={coordinate.longitude}
//                                 active={coordinate.active}
//                                 text={`Truck ${coordinate.number}`}
//                             />
//                         ))
//                     }
                                
//                 </GoogleMapReact>                     */}
//             </div>
//         </div>
//     )
//     return(
//         <Layout
//             title='Truck Map'
//             description='Truck Map'
//             className='container col-md-8 offset-md-2'
//         >                        
//             <RenderComponent />
//         </Layout>
//     )
// }
// export default TruckMap
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import { api } from '../utils/Api'
import Layout from '../layout/Layout'
import GoogleMap from './GoogleMap';
import './GoogleMap.css'
import Modal from 'react-bootstrap/Modal';
// InfoWindow component
const InfoWindow = (props) => {
  const { truck } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: 150,
    left: '-45px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
    borderRadius: 10
  };
  let color = '#bbb'
  let text = 'Not moving'
  if(truck.direction === 1 && truck.plant === -1){
    color = 'yellow'
    text = 'Back to plant'
  }else if(truck.direction === -1 && truck.plant === -1){
    color = 'green'
    text = 'In Progress'
  }else if(truck.plant === 0){
    color = '#ED1C24'
    text = 'On Mission plant'
  }else if(truck.plant === 1){
    color = '#233F86'
    text = 'On San Benito plant'
  }
//   switch (truck.direction) {
//     case 1:
//         color = 'yellow'
//         text = 'Back to plant'
//         break;
//     case -1:
//         color = 'green'
//         text = 'In Progress'
//         break;
//     default:
//         break;
//   }
  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16, fontWeight: 'bold' }}>
        Truck {truck.number}
      </div>
      <div style={{ fontSize: 14}}>
        <b>Latitude:</b> {truck.latitude}
      </div>
      <div style={{ fontSize: 14}}>
        <b>Longitude:</b> {truck.longitude}
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div style={{borderRadius: '50%', width: 10, height: 10, backgroundColor: `${color}`, marginRight: 10}} />
        {text}
      </div>
    </div>
  );
};
// Marker component
const Marker = ({ show, truck, hover }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 20,
    width: 20,
    backgroundColor: hover ? '#ED1C24' : '#233F86',
    cursor: 'pointer',
    zIndex: 10,
    display: 'flex',
    transform: hover ? 'scale(2,2)' : 'scale(1,1)',
  };
  
  const textStyle = {
    margin: 'auto',
    color: 'white'
  }
  return (
    <>
      <div style={markerStyle} className='effect' >
        <p style={textStyle}>{truck.number}</p>
      </div>
      {show && <InfoWindow truck={truck} hover={hover} />}
    </>
  );
};
class MarkerInfoWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trucks: [],
      trucksPlantMission: [],
      trucksPlantSanBenito: [],
      trucksBackToPlant: [],
      trucksInProgress: [],
      center: {lat: 26.2787785, lng: -98.4021818},
      googleApi: process.env.GOOGLE_MAP_API || "AIzaSyBHoAVpJCFT_egKztdNYjK3JpPeMZ946ZU",
      showModal: false,
      selectedTrucks: [],
      title: ''
    };
  }
    getTruckList = async () => {
    const url = api + '/trucklistmap'        
    const token = localStorage.getItem('token')
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    try {
        const { data } = await axios.get(url)   
        let coordinatesTrucks = []
        let centerCoordinates = {}
        data.map((item, index) => {
            if(item.latitude && item.longitude){
                if(index === 0){
                    centerCoordinates = {lat: item.latitude, lng: item.longitude}
                }
                coordinatesTrucks.push({
                    number: item.number,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    show: false,
                    hover: false,
                    direction: item.direction,
                    plant: item.plant
                })
            }
        }) 
        let inProgress = []
        let backToPlant = []
        let plantMission = []
        let plantSanBenito = []
        inProgress = data.filter(obj => obj.direction === -1 && obj.latitude && obj.longitude && obj.plant === -1)
        backToPlant = data.filter(obj => obj.direction === 1 && obj.latitude && obj.longitude && obj.plant === -1)
        // plantMission = data.filter(obj => obj.direction === 0 && obj.latitude && obj.longitude)
        plantMission = data.filter(obj => obj.plant === 0)
        plantSanBenito = data.filter(obj => obj.plant === 1)
        this.setState({
            trucks: coordinatesTrucks,
            center: centerCoordinates,
            trucksInProgress: inProgress,
            trucksBackToPlant: backToPlant,
            trucksPlantMission: plantMission,
            trucksPlantSanBenito: plantSanBenito
        })
    } catch (err) {
        console.error(err)
        return null
    }
}
    componentDidMount() {
        this.getTruckList()
    }
    onChildClickCallback = (key) => {
        const index = this.state.trucks.findIndex((e) => e.number === key);
        this.state.trucks[index].show = !this.state.trucks[index].show; 
        this.setState({
            trucks: this.state.trucks
        })
    };
    onChildMouseEnter = (key) => {
        const index = this.state.trucks.findIndex((e) => e.number === key);
        this.state.trucks[index].hover = true; 
        this.state.trucks[index].show = true;
        this.setState({
            trucks: this.state.trucks
        })
    }
    onChildMouseLeave = (key) => {
        const index = this.state.trucks.findIndex((e) => e.number === key);
        this.state.trucks[index].hover = false; 
        this.state.trucks[index].show = false;
        this.setState({
            trucks: this.state.trucks
        })
    }
    componentTable = () => {
        return <div className='col-md-2'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Back to plant trucks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.trucksBackToPlant.length !== 0 ? this.state.trucksBackToPlant.map((item) => (
                            <tr>
                                <td>{item.number}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td>No trucks</td>
                        </tr>
                    }
                </tbody>
            </table>
            <table className='table'>
                <thead>
                    <tr>
                        <th>In Progress trucks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.trucksInProgress.length !== 0 ? this.state.trucksInProgress.map((item) => (
                            <tr>
                                <td>{item.number}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td>No trucks</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }
    componentTable2 = () => {
        return <div className='col-md-2'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>On San Benito plant trucks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.trucksPlantSanBenito.length !== 0 ? this.state.trucksPlantSanBenito.map((item) => (
                            <tr>
                                <td>{item.number}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td>No trucks</td>
                        </tr>
                    }
                </tbody>
            </table>
            <table className='table'>
                <thead>
                    <tr>
                        <th>On Mission plant trucks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.trucksPlantMission.length !== 0 ? this.state.trucksPlantMission.map((item) => (
                            <tr>
                                <td>{item.number}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td>No trucks</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }
    renderSingleCount = (color, text, type) => {
        return <div className='col-md-2' style={{
            backgroundColor: 'white',
            boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
            padding: 10,
            fontSize: 14,
            borderRadius: 10,
            cursor: 'pointer',
        }}
        onClick={() => this.toggleSelectedTrucks(type)}
        >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{borderRadius: '50%', width: 10, height: 10, backgroundColor: `${color}`, marginRight: 10}} />
                <div style={{ fontWeight: 'bold'}}>{text}</div>
            </div>
        </div>
    }
    renderCountTrucks = () => {
        return <div className='container row justify-content-around offset-md-1 mb-4 '> 
            {this.renderSingleCount('yellow', `Back to plant trucks - ${this.state.trucksBackToPlant.length}`,'backToPlant')}
            {this.renderSingleCount('green', `In Progress trucks - ${this.state.trucksInProgress.length}`,'inProgress')}
            {this.renderSingleCount('#ED1C24', `Trucks on Mission plant - ${this.state.trucksPlantMission.length}`,'missionPlant')}
            {this.renderSingleCount('#233F86', `Trucks on San Benito plant - ${this.state.trucksPlantSanBenito.length}`,'sanBenitoPlant')}
        </div>
    }
    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }
    toggleSelectedTrucks = (type) => {
        switch (type) {
            case 'backToPlant':
                this.setState({
                    showModal: !this.state.showModal,
                    selectedTrucks: this.state.trucksBackToPlant,
                    title: 'Back to plant trucks'
                })
                break;
            case 'inProgress':
                this.setState({
                    showModal: !this.state.showModal,
                    selectedTrucks: this.state.trucksInProgress,
                    title: 'In Progress trucks'
                })
                break;
            case 'missionPlant':
                this.setState({
                    showModal: !this.state.showModal,
                    selectedTrucks: this.state.trucksPlantMission,
                    title: 'On Mission plant trucks'
                })
                break;
            default:
                this.setState({
                    showModal: !this.state.showModal,
                    selectedTrucks: this.state.trucksPlantSanBenito,
                    title: 'On San Benito plant trucks'
                })
                break;
        }
    }
    displaySelectedTrucks = () =>{
        return <div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Number</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.selectedTrucks.length !== 0 ? this.state.selectedTrucks.map((item) => (
                            <tr>
                                <td>{item.number}</td>
                                <td>{item.latitude}</td>
                                <td>{item.longitude}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td colSpan={3} className='text-center'>No trucks</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }
    render() {
    const { trucks, center, googleApi } = this.state;
    return (
      <Layout
            title='Truck Map'
            description='Truck Map'
            className=''
        >
            {this.renderCountTrucks()}
            <div className='container row offset-md-1'>
                {this.componentTable()}
                <div className='col-md-8'>
                    <GoogleMap
                        defaultZoom={10}
                        defaultCenter={center}
                        bootstrapURLKeys={{ key: googleApi }}
                        onChildClick={this.onChildClickCallback}
                        onChildMouseEnter={this.onChildMouseEnter}
                        onChildMouseLeave={this.onChildMouseLeave}
                        >
                        {trucks.map((truck) => (
                            <Marker
                            key={truck.number}
                            lat={truck.latitude}
                            lng={truck.longitude}
                            show={truck.show}
                            hover={truck.hover}
                            truck={truck}
                            />
                        ))}
                    </GoogleMap>
                </div>
                {this.componentTable2()}
            </div>
            
            <Modal
                show={this.state.showModal}
                animation={false}
                onHide={this.toggleModal}
                size='sm'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.displaySelectedTrucks()}
                </Modal.Body>
            </Modal>
            
      </Layout>
    );
  }
}
InfoWindow.propTypes = {
    hover: PropTypes.bool.isRequired,
    truck: PropTypes.shape({
        number: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
};
Marker.propTypes = {
    show: PropTypes.bool.isRequired,
    hover: PropTypes.bool.isRequired,
    truck: PropTypes.shape({
        number: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
};
export default MarkerInfoWindow;