import { Button, Col, Form, Input, Popconfirm, Row, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { api } from '../utils/Api';
import axios from 'axios';
import { CustomSpinner } from '../utils/CustomComponents'

const Batch = (props) => {
    const [form] = Form.useForm()
    const [initialValues, setInitialValues] = useState({})
    const [plantOptions, setPlantOptions] = useState([])
    const [subplantOptions, setSublantOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(true)
    const [loadingSubplant, setLoadingSubplant] = useState(true)
    const [email, setEmail] = useState('')
    const [apiNotification, contextHolder] = notification.useNotification()

    const openNotification = (type, message, description) => {
        apiNotification[type]({
            message: message,
            description: description
        })
    }

    useEffect(() => {
        getPlant()
        getBatch()
    }, [])
    
    const getPlant = async () => {
        const url = api + '/plant'
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let plantList = []
            data.map((item) => {
                plantList.push({
                    label: item.name,
                    value: item._id
                })
            })
            setPlantOptions(plantList)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getSublant = async (currentPlant) => {
        const url = api + `/plant/${currentPlant}`
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            let subplantList = []
            data.subplants.map((item) => {
                subplantList.push({
                    label: item.name,
                    value: item.name
                })
            })
            setSublantOptions(subplantList)
            setLoadingSubplant(false)
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const getBatch = async () => {
        const url = api + '/users/' + props.match.params.id        
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

        try {
            const { data } = await axios.get(url)
            setInitialValues({
                ...initialValues,
                name: data.name,
                email: data.email,
                plant: data.plant,
                subplant: data.subplant
            })
            setEmail(data.email)
            getSublant(data.plant)
            setLoadingForm(false)
        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to get user')
            console.error(err.message)
            return null
        }
    }

    const deleteBatch = async () => {
        setLoading(true)

        const url = api + '/users/' + props.match.params.id
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            await axios.delete(url)
            openNotification('info', 'Info', 'Batch Deleted')
            setLoading(false)
            props.history.push('/batchlist')
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            return null
        }
    }

    const duplicatedUserEmail = async (email) => {
        const url = api + '/useremail/' + email
        const token = localStorage.getItem('token')
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

        try {
            const { data } = await axios.get(url)
            return data.found
        } catch (err) {
            console.error(err.message)
            return null
        }
    }

    const updateBatch = async (data) => {
        const user = {
            name: data.name,
            email: data.email,
            plant: data.plant,
            subplant: data.subplant,
        }

        try {
            let url = api + '/users/' + props.match.params.id  
            const token = localStorage.getItem('token')
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

            await axios.patch(url, user)
            openNotification('success', 'Success', 'Batch updated')
            setLoading(false)
            props.history.push('/batchlist')

        } catch (err) {
            setLoading(false)
            openNotification('error', 'Error', 'Error while trying to update user')
            console.error(err.message)
            return null
        }
    }
    
    const validateUser = async (values) => {
        if(values.email === email){
            updateBatch(values)
        }else{
            const duplicadoEmail = await duplicatedUserEmail(values.email)
            
            if (duplicadoEmail) {
                openNotification('warning', 'Duplicated user', 'User alredy exist with Email')
                setLoading(false)
            }else {
                setLoading(false)
                updateBatch(values)
            }
        }
    }

    const onFinish = (values) => {
        setLoading(true)
        validateUser(values)
    }

    const onChangePlant = (value) => {
        form.setFieldsValue({subplant: ''})
        setLoadingSubplant(true)
        getSublant(value)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())


    const CustomForm = () => {
        return <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please fill name'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please fill email'
                        }
                    ]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        label='Plant'
                        name='plant'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a plant'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select plant`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={plantOptions}
                            onChange={onChangePlant}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        label='Subplant'
                        name='subplant'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a subplant'
                            }
                        ]}
                        >
                            <Select
                            showSearch
                            placeholder={`Select subplant`}
                            optionFilterProp="children"
                            size='large'
                            filterOption={filterOption}
                            options={subplantOptions}
                            disabled={loadingSubplant}
                            style={{
                                width: '100%'
                            }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            <Row gutter={16}>
                <Col>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Col>
                {/* <Col>
                    <Popconfirm
                        title="Delete the user"
                        description="Are you sure to delete this user?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={deleteBatch}
                    >
                        <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                    </Popconfirm>
                </Col> */}
            </Row>
        </Form>
    }

    return (
        <Layout
        title='Batch'
        description='Edit Batch'
        type='medium'
        >
            {contextHolder}
            {loadingForm ? <CustomSpinner/> : <CustomForm />}
        </Layout>
    )
}

export default Batch